var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('Sales Discounts'))+" ")]),(_vm.$checkRole('sales_payments_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/salesDiscounts/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('Addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('FindACheck'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('There are no sales discounts'),"filter":{ customer_id: _vm.$route.params.id },"emptyTableSubText":_vm.$parent.$t('Try adding some items to the table and try again'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('Discount'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'client_type',
          title: _vm.$t('Customer name'),
          type: 'clintLink',
          sort: true
        },
        {
          column: 'date',
          title: _vm.$parent.$t('Date'),
          type: 'text',
          sort: true
        },
        {
          column: 'description',
          title: _vm.$parent.$t('Thats about'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$parent.$t('Amount'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$parent.$t('Settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('sales_payments_edit')
            },
            {
              name: 'delete',
              role: _vm.$checkRole('sales_payments_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$parent.$t('Attention'),
        areYouReallySureToDeleteTheItem: _vm.$parent.$t('Are you really sure to delete the item'),
        close: _vm.$parent.$t('Close'),
        confirm: _vm.$parent.$t('Confirm'),
        loading: _vm.$parent.$t('Loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }