<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('Signatures')" :description="$t('from Control Your Lights')" />
        <indexTable
          :searchText="$t('search')"
          :emptyTableText="$t('There Signatures')"
          :buttonRole="$checkRole('products_add')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :cloumns="[
            {
              column: 'name',
              title: $t('Job Title'),
              type: 'text',
              sort: true
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                {
                  name: 'edit',

                  role: $checkRole('signatures_edit')
                },
                {
                  name: 'delete',

                  role: $checkRole('signatures_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/signatures'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
