<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('purchasePayments') }}
        </h4>
        <router-link
          class="btn btn-sm btn-primary"
          v-if="$parent.item.stat == 1 && $checkRole('purchase_payments_add')"
          title="$t('Add a batch')"
          :to="'/purchasePayments/create?customer_id=' + $route.params.id"
        >
          <i class="fas fa-plus"></i>
          {{ $t('Addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('FindABatch')"
        :withoutCard="true"
        :emptyTableText="$parent.$t('There are no purchase payments')"
        :filter="{ customer_id: $route.params.id }"
        :emptyTableSubText="$parent.$t('Try adding some items to the table and try again')"
        :cloumns="[
          {
            column: 'code',
            title: $t('Payment'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'client_type',
            title: $t('Supplier name'),
            type: 'clintLink',
            sort: true
          },
          {
            column: 'date',
            title: $parent.$t('Payment date'),
            type: 'text',
            sort: true
          },
          {
            column: 'bill_id',
            title: $parent.$t('Bill'),
            type: 'linkInvoice',
            to: 'bill',
            sort: true,
            link: true
          },
          {
            column: 'description',
            title: $parent.$t('Thats about'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: $parent.$t('Amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: $parent.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $checkRole('purchase_payments_edit')
              },
              { name: 'printPurchasePayment', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: $checkRole('purchase_payments_delete')
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $parent.$t('Attention'),
          areYouReallySureToDeleteTheItem: $parent.$t('are You Really Sure To Delete The sItem'),
          close: $parent.$t('Close'),
          confirm: $parent.$t('Confirm'),
          loading: $parent.$t('Loading')
        }"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/purchasePayments'
    }
  },
  components: {
    indexTable
  }
}
</script>
