var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Stores'),"description":_vm.$t('From here you can control your product stores'),"explanationPath":_vm.path + '/explaination',"showBarcode":'true',"barcodePath":'productsBarcode'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for a store'),"emptyTableText":_vm.$t('There are no stores'),"buttonRole":_vm.$checkRole('stores_add'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          { column: 'id', title: _vm.$t('Code'), type: 'mainLink', sort: true },
          {
            column: 'name',
            title: _vm.$t('Store name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'country',
            title: _vm.$t('Country'),
            type: 'text',
            sort: true,
            icon: 'city'
          },
          {
            column: 'city',
            title: _vm.$t('City'),
            type: 'text',
            sort: true,
            icon: 'building'
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$checkRole('stores_edit') },
              {
                name: 'link',
                role: _vm.$checkRole('stores_transfers_add'),
                title: _vm.$t('Move from the store'),
                icon: 'suitcase',
                link: '/storesTransfers/create?from_store_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('stores_deposits_add'),
                title: _vm.$t('Deposit to the store'),
                icon: 'donate',
                link: '/storesDeposits/create?store_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('stores_withdrawals_add'),
                title: _vm.$t('Pull out of the store'),
                icon: 'money-bill-alt',
                link: '/storesWithdrawals/create?store_id='
              },
              // {
              //   name: 'moveStore',
              //   role: $checkRole('stores_transfers_add'),
              //   title: $t('Move all goods to store'),
              //   icon: 'suitcase'
              // },
              {
                name: 'delete',
                role: _vm.$checkRole('stores_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }