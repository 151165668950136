var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Marketers'),"description":_vm.$t('From Here You Can Control The Marketers')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search For A Marketer'),"emptyTableText":_vm.$t('There Are No Marketers'),"buttonRole":true,"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('Marketer name'),
            type: 'text',
            sort: true
          },
          {
            column: 'balance',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'edit', role: true },
              { name: 'delete', role: true }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }