var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports'),"description":_vm.$t('customer Account Details')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Report options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('customer'),"plaseholder":_vm.$t('choose Customer'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('customer Account Details'),"details":[
            {
              title: _vm.$t('customer Name'),
              value: _vm.customerName
            }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 9, value: _vm.$t('opening Balance') },
            {
              colspan: 1,
              value: _vm.startBalance
            }
          ],"emptyTableText":_vm.$t('there No Data The Moment'),"emptyTableSubText":_vm.$t('try Adding Some And Try Again'),"cloumns":[
            {
              column: 'check',
              title: '#',
              type: 'check'
            },
            {
              column: 'code',
              title: _vm.$t('code'),
              type: 'reportCode'
            },
            {
              column: 'date',
              title: _vm.$t('Date'),
              type: 'text'
            },
            {
              column: 'type',
              title: _vm.$t('type'),
              type: 'reportType'
            },
            {
              column: 'statement',
              title: _vm.$t('statement'),
              type: 'statement'
            },
            {
              column: 'quantity',
              title: _vm.$t('Quantity'),
              type: 'textOrEmpty'
            },
            {
              column: 'price',
              title: _vm.$t('Price'),
              type: 'textOrEmpty'
            },
            {
              column: 'debit',
              title: _vm.$t('debtor'),
              type: 'textOrEmpty'
            },
            {
              column: 'creditor',
              title: _vm.$t('Creditor'),
              type: 'textOrEmpty'
            },
            {
              column: 'new_balance',
              title: _vm.$t('Balance'),
              type: 'text'
            }
          ],"footer":[
            { colspan: 7, value: _vm.$t('the Final Balance') },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            {
              colspan: 1,
              value: _vm.endBalance
            }
          ],"tafqeet":_vm.tafqeet}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }