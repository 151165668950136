var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Purchases'),"description":_vm.$t('From here you can control your purchase reports')}})],1),_c('div',{staticClass:"col-md-6"},[(
        (_vm.$user.role.available_reports.find(
          (el) => el.id == 5 || el.id == 6 || el.id == 7 || el.id == 8
        ) ||
          _vm.$user.admin) &&
        _vm.$site.suppliers_allow
      )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Suppliers'),"icon":'fa-user-tie',"reports":[
        {
          name: _vm.$t('Total Resource Account'),
          link: './suppliersTotalAccounts',
          role: _vm.$user.role.available_reports.find((el) => el.id == 5) || _vm.$user.admin
        },
        {
          name: _vm.$t('Supplier Account Details'),
          link: '/suppliersDetailsAccounts',
          role: _vm.$user.role.available_reports.find((el) => el.id == 6) || _vm.$user.admin
        },
        {
          name: _vm.$t('suppliers Balances'),
          link: '/suppliersBalance',
          role: _vm.$user.role.available_reports.find((el) => el.id == 7) || _vm.$user.admin
        },
        {
          name: _vm.$t('Supplier Guide'),
          link: '/suppliersGuide',
          role: _vm.$user.role.available_reports.find((el) => el.id == 8) || _vm.$user.admin
        },
        {
          name: _vm.$t('inactive Suppliers'),
          link: '/suppliersInactive',
          role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
        },
        {
          name: _vm.$t('supplier Movement'),
          link: '/supplierMovement',
          role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
        },
        {
          name: _vm.$t('purchasing Collecting'),
          link: '/purchasingCollecting',
          role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
        }
      ]}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[(
        (_vm.$user.role.available_reports.find((el) => el.id == 15 || el.id == 16) || _vm.$user.admin) &&
        _vm.$site.bills_allow
      )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('purchases'),"icon":'fa-shopping-cart',"reports":[
        {
          name: _vm.$t('Purchases By Invoice'),
          link: './purchasesByBill',
          role: _vm.$user.role.available_reports.find((el) => el.id == 15) || _vm.$user.admin
        },
        {
          name: _vm.$t('Purchases By Product'),
          link: '/purchasesByItem',
          role: _vm.$user.role.available_reports.find((el) => el.id == 16) || _vm.$user.admin
        }
      ]}}):_vm._e(),(
        (_vm.$user.role.available_reports.find((el) => el.id == 23 || el.id == 24) || _vm.$user.admin) &&
        _vm.$site.orders_allow
      )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Orders'),"icon":'fa-briefcase',"reports":[
        {
          name: _vm.$t('Orders By Invoice'),
          link: './purchasesOrdersByBill',
          role: _vm.$user.role.available_reports.find((el) => el.id == 23) || _vm.$user.admin
        },
        {
          name: _vm.$t('Orders By Product'),
          link: '/purchasesOrdersByItem',
          role: _vm.$user.role.available_reports.find((el) => el.id == 24) || _vm.$user.admin
        }
      ]}}):_vm._e(),(
        (_vm.$user.role.available_reports.find((el) => el.id == 19 || el.id == 20) || _vm.$user.admin) &&
        _vm.$site.bills_allow
      )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Bills'),"icon":'fa-exchange',"reports":[
        {
          name: _vm.$t('Returned By Invoice'),
          link: './purchasesReturnsByBill',
          role: _vm.$user.role.available_reports.find((el) => el.id == 19) || _vm.$user.admin
        },
        {
          name: _vm.$t('Returned By Product'),
          link: '/purchasesReturnsByItem',
          role: _vm.$user.role.available_reports.find((el) => el.id == 20) || _vm.$user.admin
        }
      ]}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }