var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('manufacturingOperations.OperationsList'),"description":_vm.$t('manufacturingOperations.FromHereYouCanControlTheListOfOperations')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingOperations.SearchForAnOperation'),"emptyTableText":_vm.$t('manufacturingOperations.ThereAreNoOperations'),"emptyTableSubText":_vm.$t('manufacturingOperations.TryAddingItemsToTheTableAndTryAgain'),"buttonRole":_vm.$checkRole('manufacturing_operations_add'),"cloumns":[
          {
            column: 'code',
            title: _vm.$t('manufacturingOperations.OperationCode'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'start_date',
            title: _vm.$t('manufacturingOperations.StartDate'),
            type: 'text',
            sort: true
          },
          {
            column: 'end_date',
            title: _vm.$t('manufacturingOperations.EndDate'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: _vm.$t('manufacturingOperations.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'price',
            title: _vm.$t('manufacturingOperations.price'),
            type: 'text',
            sort: true
          },
          {
            column: 'invoice_id',
            title: _vm.$t('salesPayments.invoice'),
            type: 'linkInvoice',
            to: 'invoice',
            sort: true,
            link: true
          },
          {
            column: 'description',
            title: _vm.$t('manufacturingOperations.description'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('manufacturingOperations.Status'),
            type: 'status',
            sort: true,
            values: [
              {
                title: _vm.$t('manufacturingOperations.ItIsOpen'),
                value: 0,
                color: 'dark'
              },
              {
                title: _vm.$t('manufacturingOperations.InProgress'),
                value: 1,
                color: 'primary'
              },
              {
                title: _vm.$t('manufacturingOperations.Very'),
                value: 2,
                color: 'success'
              },
              {
                title: _vm.$t('manufacturingOperations.canceled'),
                value: 3,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('manufacturingOperations.Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('manufacturing_operations_edit')
              },
              { name: 'file' },
              {
                name: 'link',
                role: _vm.$checkRole('manufacturing_operations_edit'),
                title: 'إيداع خامات',
                icon: 'conveyor-belt-alt',
                link: '/manufacturingDeposits/create?manufacturing_operation_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('manufacturing_operations_edit'),
                title: 'سحب المنتجات',
                icon: 'conveyor-belt',
                link: '/manufacturingWithdrawals/create?manufacturing_operation_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('manufacturing_operations_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }