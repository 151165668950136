<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('shipping Addresses')"
          :description="$t('from Here You Control The Shipping Addresses')"
        />
        <indexTable
          :searchText="$t('searchAddress')"
          :emptyTableText="$t('thereAreNoCustomers')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('customers_add')"
          :localData="true"
          :cloumns="[
            {
              column: 'customer_id',
              title: $t('Customer name'),
              type: 'link',
              to: 'customer',
              sort: true,
              link: true
            },
            {
              column: 'address1',
              title: $t('theAddress'),
              type: 'text',
              sort: true,
              icon: 'map-marker-alt'
            },
            {
              column: 'mobile',
              title: $t('mobile'),
              type: 'text',
              sort: true,
              icon: 'phone'
            },
            {
              column: 'city_id',
              title: $t('City'),
              type: 'link',
              to: 'city',
              sort: true,
              link: false,
              icon: 'city'
            },
            {
              column: 'options',
              title: $t('Actions'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $checkRole('customers_edit')
                },
                {
                  name: 'delete',
                  role: $checkRole('customers_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
    <importForm v-if="importShow" />
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
import importForm from './components/importForm.vue'

export default {
  data() {
    return {
      path: '/shippingAddresses',
      importShow: false
    }
  },
  methods: {
    importForm() {
      this.importShow = true
    }
  },
  components: {
    indexHeader,
    indexTable,
    importForm
  }
}
</script>
