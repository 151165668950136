var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Employees'),"description":_vm.$t('From here you can control employees')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Find an employee'),"emptyTableText":_vm.$t('There are no employees'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('employees_add'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('Name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'job_title',
            title: _vm.$t('Job title'),
            type: 'text',
            sort: true,
            icon: 'user-tie'
          },
          {
            column: 'salary',
            title: _vm.$t('Salary'),
            type: 'text',
            sort: true,
            icon: 'coins'
          },
          {
            column: 'balance',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('employees_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('Add a salary'),
                icon: 'money-check-alt',
                link: '/employeesSalaries/create?employee_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('Incentive'),
                icon: 'hand-holding-usd',
                link: '/employeesIncentives/create?employee_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('Deduct'),
                icon: 'funnel-dollar',
                link: '/employeesDeductions/create?employee_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('Balance withdrawal'),
                icon: 'dollar-sign',
                link: '/employeesWithdrawals/create?employee_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('employees_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }