var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports'),"description":_vm.$t('accounts Deposit Record')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('report Options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('account'),"plaseholder":_vm.$t('chooseAccount'),"show":'name',"refrance":'id',"value":_vm.params.account_id,"values":_vm.$database.accounts},model:{value:(_vm.params.account_id),callback:function ($$v) {_vm.$set(_vm.params, "account_id", $$v)},expression:"params.account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('theUser'),"plaseholder":_vm.$t('choose User'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('treasury'),"plaseholder":_vm.$t('ChooseSafe'),"show":'name',"refrance":'id',"value":_vm.params.safe_id,"values":_vm.$database.safes},model:{value:(_vm.params.safe_id),callback:function ($$v) {_vm.$set(_vm.params, "safe_id", $$v)},expression:"params.safe_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('accounts Deposit Record'),"details":[
            { title: _vm.$t('account'), value: _vm.supplierName },
            { title: _vm.$t('userName'), value: _vm.userName },
            { title: _vm.$t('treasury'), value: _vm.safeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there No Data The Moment'),"emptyTableSubText":_vm.$t('try Adding Some And Try Again'),"cloumns":[
            {
              column: 'code',
              link: '/accountsDeposits',
              title: _vm.$t('code'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'date',
              title: _vm.$t('Date'),
              type: 'text',
              sort: true
            },
            {
              column: 'partner',
              title: _vm.$t('account'),
              type: 'text',
              sort: true
            },
            {
              column: 'user',
              title: _vm.$t('theUser'),
              type: 'text',
              sort: true
            },
            {
              column: 'safe',
              title: _vm.$t('treasury'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: _vm.$t('theAmount'),
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 5, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.cost }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }