<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Suppliers')"
          :description="$t('From here you can control suppliers')"
          :showExport="'true'"
          :exportLink="path + '/export/excel'"
          :showImport="'true'"
          :explanationPath="path + '/explaination'"
        />
        <indexTable
          :searchText="$t('Search for supplier')"
          :emptyTableText="$t('There are no suppliers')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('suppliers_add')"
          :localData="true"
          :cloumns="[
            { column: 'id', title: 'كود', type: 'mainLink', sort: true },
            {
              column: 'name',
              title: $t('Supplier name'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'mobile',
              title: $t('Phone number'),
              type: 'text',
              sort: true,
              icon: 'phone'
            },
            {
              column: 'balance',
              title: $t('Balance'),
              type: 'text',
              sort: true,
              icon: 'usd-square'
            },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'stat',
              sort: true,
              hideMobile: true
            },
            {
              column: 'options',
              title: $t('Actions'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $checkRole('suppliers_edit')
                },
                {
                  name: 'link',
                  role: $checkRole('purchase_payments_add'),

                  title: $t('Add a batch'),
                  icon: 'dollar-sign',
                  link: '/purchasePayments/create?supplier_id='
                },
                {
                  name: 'link',
                  role: $checkRole('bills_add'),

                  title: $t('Add a purchase bill'),
                  icon: 'file-invoice-dollar',
                  link: '/bills/create?supplier_id='
                },
                {
                  name: 'link',
                  role: $checkRole('purchase_orders_add'),
                  title: $t('Add a purchase order'),
                  icon: 'briefcase',
                  link: '/purchaseorders/create?supplier_id='
                },
                {
                  name: 'delete',
                  role: $checkRole('suppliers_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t(
              'Are you really sure you deleted the resource if you delete the resource all invoices and processes will Be deleted'
            ),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
    <!-- / .row -->
    <importForm v-if="importShow"></importForm>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
import importForm from './components/importForm.vue'

export default {
  data() {
    return {
      path: '/suppliers',
      importShow: false
    }
  },
  methods: {
    importForm() {
      this.importShow = true
    }
  },
  components: {
    indexHeader,
    indexTable,
    importForm
  }
}
</script>
