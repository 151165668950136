<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('pointOfSales')" :description="$t('pointOfSalesDescription')" />
        <indexTable
          :searchText="$t('FindAPointOfSale')"
          :emptyTableText="$t('thereAreNopointOfSales')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('point_of_sales_add')"
          :cloumns="[
            {
              column: 'name',
              title: $t('name'),
              type: 'text',
              sort: true
            },
            {
              column: 'store_id',
              title: $t('store'),
              type: 'link',
              to: 'store',
              sort: true,
              link: false
            },
            {
              column: 'safe_id',
              title: $t('safe'),
              type: 'link',
              to: 'safe',
              sort: true,
              link: false
            },
            {
              column: 'balance',
              title: $t('Balance'),
              type: 'text',
              sort: true,
              disable: !$checkRole('point_of_sales_show_balance') ? 1 : 0
            },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'stat',
              sort: true
            },
            {
              column: 'password',
              title: $t('DeviceCode'),
              type: 'text',
              sort: true
            },
            {
              column: 'session_number',
              title: $t('SessionNo'),
              type: 'text',
              sort: true
            },

            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'pointOfSale' },
                {
                  name: 'pointOfSaleReport',
                  role: $checkRole('reports_show'),
                  title: 'تقرير المبيعات',
                  icon: 'chart-pie',
                  link: '/salesByItem?session_number= ' + +'&point_of_sale_id='
                },
                {
                  name: 'newSession',
                  role: $checkRole('point_of_sales_edit')
                },
                {
                  name: 'posLink',
                  role: $checkRole('safes_deposits_add'),
                  title: $t('safes.DepositToTheSafe'),
                  icon: 'donate',
                  link: '/safesDeposits/create'
                },
                {
                  name: 'posLink',
                  role: $checkRole('safes_withdrawals_add'),
                  title: $t('safes.WithdrawalFromTheTreasury'),
                  icon: 'money-bill-alt',
                  link: '/safesWithdrawals/create'
                },
                {
                  name: 'posLinkTransefere',
                  role: $checkRole('safes_transfers_add'),
                  title: $t('safes.TransferFromTheTreasury'),
                  icon: 'suitcase',
                  link: '/safesTransfers/create'
                },
                {
                  name: 'edit',
                  role: $checkRole('point_of_sales_edit')
                },
                {
                  name: 'delete',
                  role: $checkRole('point_of_sales_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
    <changeSession v-if="changePointOfSale" />
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

import changeSession from './components/changeSession.vue'

export default {
  data() {
    return {
      path: '/pointOfSales',
      changePointOfSale: false
    }
  },
  methods: {
    newSession(id) {
      this.changePointOfSale = id
    }
  },
  components: {
    indexHeader,
    indexTable,
    changeSession
  }
}
</script>
