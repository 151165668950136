<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('Sale installments') }}
        </h4>
        <router-link
          class="btn btn-sm btn-primary"
          v-if="$parent.item.stat == 1 && $checkRole('sales_installments_add')"
          :title="$t('Add premium')"
          :to="'/salesInstallments/create?supplier_id=' + $route.params.id"
        >
          <i class="fas fa-plus"></i> {{ $t('Addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('Search for premium')"
        :withoutCard="true"
        :emptyTableText="$t('There are no purchase cheques')"
        :filter="{ supplier_id: $route.params.id }"
        :emptyTableSubText="$t('Try adding some items to the table and try again')"
        :cloumns="[
          {
            column: 'code',
            title: $t('Paid'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'supplier_id',
            title: $t('Supplier name'),
            type: 'link',
            to: 'supplier',
            sort: true,
            link: true
          },
          {
            column: 'created_date',
            title: $t('Date Registration'),
            type: 'text',
            sort: true
          },
          {
            column: 'date',
            title: $t('Payment date'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: $t('Thats about'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: $t('Amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'payment_method_id',
            title: $t('Payment method'),
            type: 'link',
            to: 'paymentMethod',
            sort: true,
            link: false
          },
          {
            column: 'stat',
            title: $t('The condition'),
            type: 'status',
            sort: true,
            values: [
              {
                title: $t('Pending'),
                value: 0,
                color: 'info'
              },
              {
                title: $t('Driven'),
                value: 1,
                color: 'success'
              }
            ]
          },
          {
            column: 'options',
            title: $t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $checkRole('sales_installments_edit')
              },
              {
                name: 'pay',
                role: $checkRole('sales_installments_edit')
              },
              {
                name: 'delete',
                role: $checkRole('sales_installments_delete')
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $t('Attention'),
          areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
          close: $t('Close'),
          confirm: $t('Confirm'),
          loading: $t('Loading')
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/salesInstallments'
    }
  },
  components: {
    indexTable
  }
}
</script>
