<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Stores')"
          :description="$t('From here you can control your product stores')"
          :explanationPath="path + '/explaination'"
          :showBarcode="'true'"
          :barcodePath="'productsBarcode'"
        />
        <indexTable
          :searchText="$t('Search for a store')"
          :emptyTableText="$t('There are no stores')"
          :buttonRole="$checkRole('stores_add')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :cloumns="[
            { column: 'id', title: $t('Code'), type: 'mainLink', sort: true },
            {
              column: 'name',
              title: $t('Store name'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'country',
              title: $t('Country'),
              type: 'text',
              sort: true,
              icon: 'city'
            },
            {
              column: 'city',
              title: $t('City'),
              type: 'text',
              sort: true,
              icon: 'building'
            },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'stat',
              sort: true,
              hideMobile: true
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'edit', role: $checkRole('stores_edit') },
                {
                  name: 'link',
                  role: $checkRole('stores_transfers_add'),
                  title: $t('Move from the store'),
                  icon: 'suitcase',
                  link: '/storesTransfers/create?from_store_id='
                },
                {
                  name: 'link',
                  role: $checkRole('stores_deposits_add'),
                  title: $t('Deposit to the store'),
                  icon: 'donate',
                  link: '/storesDeposits/create?store_id='
                },
                {
                  name: 'link',
                  role: $checkRole('stores_withdrawals_add'),
                  title: $t('Pull out of the store'),
                  icon: 'money-bill-alt',
                  link: '/storesWithdrawals/create?store_id='
                },
                // {
                //   name: 'moveStore',
                //   role: $checkRole('stores_transfers_add'),
                //   title: $t('Move all goods to store'),
                //   icon: 'suitcase'
                // },
                {
                  name: 'delete',
                  role: $checkRole('stores_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/stores'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
