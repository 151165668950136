var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Products'),"description":_vm.$t('From here you can control your products'),"showExport":'true',"exportLink":_vm.path + '/export/excel',"showBarcode":'true',"barcodePath":'productsBarcode',"goldBarcodePath":'goldBarcode',"showImport":'true',"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for a product'),"emptyTableText":_vm.$t('There are no products'),"buttonRole":_vm.$checkRole('products_add'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('Product name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'code',
            title: _vm.$t('Product code'),
            type: 'text',
            sort: true,
            icon: 'file-code'
          },
          {
            column: 'barcode',
            title: _vm.$t('Barcode'),
            type: 'text',
            sort: true,
            icon: 'file-code'
          },
          {
            column: 'section_id',
            title: _vm.$t('Category'),
            type: 'link',
            to: 'section',
            sort: true,
            link: false,
            icon: 'folders'
          },
          {
            column: 'price',
            title: _vm.$t('Price'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'cost',
            title: _vm.$t('Cost'),
            type: 'text',
            sort: true,
            icon: 'dollar-sign'
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('customers_edit')
              },
              {
                name: 'delete',
                role: _vm.$checkRole('customers_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the product'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }