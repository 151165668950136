var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('shipping Addresses'),"description":_vm.$t('from Here You Control The Shipping Addresses')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('searchAddress'),"emptyTableText":_vm.$t('thereAreNoCustomers'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('customers_add'),"localData":true,"cloumns":[
          {
            column: 'customer_id',
            title: _vm.$t('Customer name'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: true
          },
          {
            column: 'address1',
            title: _vm.$t('theAddress'),
            type: 'text',
            sort: true,
            icon: 'map-marker-alt'
          },
          {
            column: 'mobile',
            title: _vm.$t('mobile'),
            type: 'text',
            sort: true,
            icon: 'phone'
          },
          {
            column: 'city_id',
            title: _vm.$t('City'),
            type: 'link',
            to: 'city',
            sort: true,
            link: false,
            icon: 'city'
          },
          {
            column: 'options',
            title: _vm.$t('Actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('customers_edit')
              },
              {
                name: 'delete',
                role: _vm.$checkRole('customers_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }