<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a>
                    <strong># {{ item.code }}</strong>
                  </a>
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('Modulation')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <a
                  :href="site + '/invoices/' + item.password + '/customer/show'"
                  class="btn btn-sm btn-primary"
                  target="_blank"
                  :title="$t('External Link')"
                >
                  <i class="fas fa-link"></i>
                </a>
                <a @click="print()" class="btn btn-sm btn-primary" :title="$t('printing')">
                  <i class="fas fa-print"></i>
                </a>
                <a
                  :href="$linkGenerator('/invoices/' + item.id + '/pdf', true)"
                  download
                  class="btn btn-sm btn-primary"
                  :title="$t('Download pdf')"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="card card-body p-5 invoice-show">
          <InvoiceComponent :item="item" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">#{{ item.code }}</h4>
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t('Created in') }}</strong> :
                {{ item.created }}
              </li>
              <li>
                <strong>{{ $t('Last update') }}</strong> :
                {{ item.updated }}
              </li>
              <li v-if="item.client_type == 0">
                <strong>{{ $t('Client') }}</strong> :
                <router-link :to="'/customers/' + item.customer.id">
                  {{ item.customer.name }}</router-link
                >
              </li>
              <li v-if="item.client_type == 1">
                <strong>{{ $t('Client') }}</strong> :
                <router-link :to="'/suppliers/' + item.supplier.id">
                  {{ item.supplier.name }}</router-link
                >
              </li>
              <li>
                <strong>{{ $t('Administrator') }}</strong> :
                {{ item.user.name }}
              </li>
            </ul>
          </div>
        </div>

        <showFile />

        <div class="card" v-if="storesHistories.length != 0">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="far fa-store-alt"></i>
              {{ $t('ware house operations') }}
            </h4>
          </div>
          <table class="table table-sm main-show">
            <thead>
              <tr>
                <th scope="col">{{ $t('Lots') }}</th>
                <th scope="col">{{ $t('Product') }}</th>
                <th scope="col">{{ $t('Store') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="storeHistory in storesHistories" :key="storeHistory.id">
                <td>
                  {{ storeHistory.type == 0 ? '+' : '-' }}
                  {{ storeHistory.quantity }}
                </td>
                <td>
                  <router-link :to="'/products/' + storeHistory.product.id" class="btn btn-light">
                    {{ storeHistory.product.name }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="'/stores/' + storeHistory.store.id" class="btn btn-light">
                    {{ storeHistory.store.name }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import QrcodeVue from 'qrcode.vue'
import showFile from '@/elements/show/showFile.vue'
import InvoiceComponent from '@/print/components/invoice.vue'

export default {
  data() {
    return {
      path: '/invoices',
      logo: localStorage.getItem('logo'),
      item: {},
      salesPayments: {},
      storesHistories: {},
      salesPaymentForm: false,
      addId: this.$route.params.id,
      amount: 0,
      type: 'show',
      loading: true,
      itemsCount: 0,
      printInvoice: false,
      site: localStorage.getItem('site')
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.salesPayments = this.item.sales_payments
        this.storesHistories = this.item.stores_histories
        this.amount = this.item.rest
        this.item.quotationItems.forEach((item) => {
          this.itemsCount += item.quantity - 0
        })
        if (this.$option.tax_invoice) {
          var sellerNameBuf = this.getTlvForValue('1', this.$option.name)

          var vatRegistrationNameBuf = this.getTlvForValue('2', this.$option.tax_number)

          var timeBuf = this.getTlvForValue('3', this.item.created_at)

          var totalInvoiceBuf = this.getTlvForValue('4', this.item.total.toFixed(1).toString())

          var taxAmountBuf = this.getTlvForValue('5', this.item.tax.toFixed(1).toString())
          var tagBufsArray = [
            sellerNameBuf,
            vatRegistrationNameBuf,
            timeBuf,
            totalInvoiceBuf,
            taxAmountBuf
          ]

          var qrCodeBuf = Buffer.concat(tagBufsArray)

          var qrCodeB64 = qrCodeBuf.toString('base64')

          this.item.qrCode = qrCodeB64
        }
        this.loading = false
      })
    },

    getTlvForValue(tagNum, tagValue) {
      var tagBuf = Buffer.from([tagNum], 'utf8')

      var tagvalueBuf = Buffer.from(tagValue, 'utf8')

      var tagValueLenBuf = Buffer.from([tagvalueBuf.length], 'utf8')

      var bufsArray = [tagBuf, tagValueLenBuf, tagvalueBuf]

      return Buffer.concat(bufsArray)
    },

    print() {
      this.$parent.printedInvoice = this.item
    }
  },
  components: {
    QrcodeVue,
    showFile,
    InvoiceComponent
  }
}
</script>
