<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :icon="'treasure-chest'"
        :title="item.name"
        :stat="item.stat"
        :statText="item.stat ? $t('Active') : $t('Inactive')"
        :subData="[
          {
            title: $t('Current balance'),
            value: this.$moneyFormat(item.balance)
          }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('safes_edit')
          }
        ]"
      />
      <showBoxs
        class="col-12"
        :boxs="[
          {
            icon: 'dollar-sign',
            title: $t('Current balance'),
            value: this.$moneyFormat(item.balance),
            class: 'col-md-3'
          },
          {
            icon: 'credit-card-front',
            title: $t('Invoices count'),
            value: item.salesPaymentsCount,
            class: 'col-md-3'
          },
          {
            icon: 'credit-card-front',
            title: $t('Quotations count'),
            value: item.purchasePaymentsCount,
            class: 'col-md-3'
          },
          {
            icon: 'usd-square',
            title: $t('Orders count'),
            value: item.expensesCount,
            class: 'col-md-3'
          }
        ]"
      />
      <showCard
        class="col-12"
        :title="$t('Account info')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'dollar-sign',
              title: $t('Balance'),
              value: this.$moneyFormat(item.balance)
            }
          ],
          scondCloum: [
            {
              icon: 'dollar-sign',
              title: $t('Opening balance'),
              value: this.$moneyFormat(item.opening_balance)
            }
          ]
        }"
      />
      <salesPayments class="col-lg-12" v-if="$checkRole('sales_payments_show')" />
      <purchasepayement class="col-lg-12" v-if="$checkRole('purchase_payments_show')" />
      <salesCheques class="col-lg-12" v-if="$checkRole('sales_cheques_show')" />
      <purchaseCheques class="col-lg-12" v-if="$checkRole('purchase_cheques_show')" />
      <expenses class="col-lg-12" v-if="$checkRole('expenses_show')" />
      <safesDeposits class="col-lg-12" v-if="$checkRole('safes_deposits_show')" />
      <safesWithdrawals class="col-lg-12" v-if="$checkRole('safes_withdrawals_show')" />
      <fromSafesTransfers class="col-lg-12" v-if="$checkRole('safes_transfers_show')" />
      <toSafesTransfers class="col-lg-12" v-if="$checkRole('safes_transfers_show')" />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import showBoxs from '@/elements/show/showBoxs.vue'

import salesPayments from './components/salesPayments.vue'
import purchasepayement from './components/purchasepayement.vue'
import salesCheques from './components/salesCheques.vue'
import purchaseCheques from './components/purchaseCheques.vue'
import expenses from './components/expenses.vue'
import safesDeposits from './components/safesDeposits.vue'
import safesWithdrawals from './components/safesWithdrawals.vue'
import fromSafesTransfers from './components/fromSafesTransfers.vue'
import toSafesTransfers from './components/toSafesTransfers.vue'

export default {
  data() {
    return {
      path: '/bankAccounts',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getItem()
  },
  methods: {
    getItem() {
      var item = this.$database.safes.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator('/safes/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
      })
    }
  },
  components: {
    showHeader,
    showCard,
    showBoxs,
    salesPayments,
    purchasepayement,
    salesCheques,
    purchaseCheques,
    expenses,
    safesDeposits,
    fromSafesTransfers,
    toSafesTransfers,
    safesWithdrawals
  }
}
</script>
