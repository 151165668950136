<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Bills')"
          :description="$t('From here you can control purchase invoices')"
        />
        <indexTable
          :searchText="$t('search for a bill')"
          :emptyTableText="$t('There are no bills')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('bills_add')"
          :statusSearch="true"
          :statusValues="[
            { title: $t('Situation'), value: '' },
            { title: $t('All'), value: '' },
            { title: $t('Open'), value: 0 },
            { title: $t('Driven'), value: 1 },
            { title: $t('Canceled'), value: 2 }
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('Bill'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'date',
              title: $t('The date of the invoice'),
              type: 'text',
              sort: true
            },
            {
              column: 'client_type',
              title: $t('Supplier name'),
              type: 'clintLink',
              sort: true
            },
            {
              column: 'total',
              title: $t('Cost'),
              type: 'text',
              sort: true
            },
            {
              column: 'rest',
              title: $t('Balance'),
              type: 'text',
              sort: true
            },
            {
              column: 'user_name',
              title: $t('Adminstrator'),
              type: 'text',
              sort: true
            },
            {
              column: 'status',
              title: $t('Situation'),
              type: 'status',
              sort: true,
              values: [
                { title: $t('Open'), value: 0, color: 'dark' },
                { title: $t('Driven'), value: 1, color: 'success' },
                { title: $t('Canceled'), value: 2, color: 'danger' }
              ]
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'edit', role: $checkRole('bills_edit') && !$option.e_invoicing },
                { name: 'file' },
                {
                  name: 'invoiceLink',
                  role: $checkRole('purchase_returns_add'),
                  title: $t('Return buying'),
                  icon: 'exchange',
                  link: '/purchaseReturns/create?bill_id='
                },
                {
                  name: 'invoiceLink',
                  role: true,
                  title: $t('Barcode print'),
                  icon: 'barcode-alt',
                  link: '/productsBarcode/bill/'
                },
                { name: 'outLink', role: true },
                { name: 'printBill', role: true },
                { name: 'download', role: true },
                {
                  name: 'delete',
                  role: $checkRole('bills_delete') && !$option.e_invoicing
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/bills'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
