<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('Store operations') }}
        </h4>
      </div>
      <indexTable
        :emptyTableText="$t('There are no products')"
        :emptyTableSubText="$t('Try adding some items to the table and try again')"
        :filter="{ product_id: $route.params.id }"
        :withoutCard="true"
        :cloumns="[
          {
            column: 'store_id',
            title: $t('Store name'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'quantity',
            title: $t('Quantity'),
            type: 'text'
          },
          {
            column: 'opreation',
            title: $t('The operation'),
            type: 'opreation'
          },
          { column: 'date', title: $t('Date'), type: 'text' },
          {
            column: 'type',
            title: $t('Operation type'),
            type: 'storeStat'
          }
        ]"
      />
    </div>
  </div>
</template>

<script>
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/storesHistories'
    }
  },
  components: {
    indexTable
  }
}
</script>
