var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Store withdrawals by invoice')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Store'),"plaseholder":_vm.$t('Choose store'),"show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Store withdrawals by invoice'),"details":[
            { title: _vm.$t('Username'), value: _vm.userName },
            { title: ' مخزن', value: _vm.storeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'code',
              link: '/bills',
              title: _vm.$t('Code'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'store_id',
              title: _vm.$t('Store name'),
              type: 'link',
              to: 'store',
              sort: true,
              link: true
            },
            { column: 'date', title: _vm.$t('Date'), type: 'text', sort: true },
            { column: 'user', title: _vm.$t('User'), type: 'text', sort: true }
          ],"footer":[
            { colspan: 3, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.invoicesCount }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }