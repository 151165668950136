<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('shippingOperations')"
          :description="$t('FromHereYouCanControlShipments')"
        />
        <indexTable
          :searchText="$t('searchForOperation')"
          :emptyTableText="$t('NoShipments')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('shippingOperations_add')"
          :statusSearch="true"
          :statusValues="[
            { title: $t('Situation'), value: '' },
            { title: $t('All'), value: '' },
            { title: $t('Draft'), value: 0 },
            { title: $t('Sent'), value: 1 },
            { title: $t('Seen'), value: 2 },
            { title: $t('Driven'), value: 3 },
            { title: $t('Canceled'), value: 4 }
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('operationCode'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'client_type',
              title: $t('Customer name'),
              type: 'clintLink',
              sort: false
            },
            {
              column: 'date',
              title: $t('Date'),
              type: 'text',
              sort: true
            },
            {
              column: 'invoice_id',
              title: $t('Invoice code'),
              type: 'linkInvoice',
              to: 'invoice',
              sort: true,
              link: true
            },
            {
              column: 'tracking_number',
              title: $t('trackingNumber'),
              type: 'text',
              sort: true
            },
            {
              column: 'status',
              title: $t('theStatus'),
              type: 'status',
              sort: true,
              values: [
                {
                  title: $t('Draft'),
                  value: 0,
                  color: 'dark'
                },
                {
                  title: $t('Sent'),
                  value: 1,
                  color: 'primary'
                },
                {
                  title: $t('Seen'),
                  value: 2,
                  color: 'primary'
                },
                {
                  title: $t('Driven'),
                  value: 3,
                  color: 'success'
                },
                {
                  title: $t('Canceled'),
                  value: 4,
                  color: 'danger'
                }
              ]
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $checkRole('shippingOperations_edit')
                },
                { name: 'printInvoice', role: true },
                {
                  name: 'delete',
                  role: $checkRole('shippingOperations_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/shippingOperations'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
