var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('invoices'),"description":_vm.$t('From here you can control sales invoices')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('search for an invoice'),"emptyTableText":_vm.$t('There are no bills'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('invoices_add'),"statusSearch":true,"statusValues":[
          {
            title: _vm.$t('Situation'),
            value: ''
          },
          {
            title: _vm.$t('All'),
            value: ''
          },
          {
            title: _vm.$t('Draft'),
            value: 0
          },
          {
            title: _vm.$t('Sent'),
            value: 1
          },
          {
            title: _vm.$t('Seen'),
            value: 2
          },
          {
            title: _vm.$t('Driven'),
            value: 3
          },
          {
            title: _vm.$t('Canceled'),
            value: 4
          }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('Invoice'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('The date of the invoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('Customer name'),
            type: 'clintLink',
            sort: false
          },
          {
            column: 'total',
            title: _vm.$t('Cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'rest',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: _vm.$t('Responsible name'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('Situation'),
            type: 'status',
            sort: true,
            values: [
              {
                title: _vm.$t('Draft'),
                value: 0,
                color: 'dark'
              },
              {
                title: _vm.$t('Sent'),
                value: 1,
                color: 'primary'
              },
              {
                title: _vm.$t('Seen'),
                value: 2,
                color: 'primary'
              },
              {
                title: _vm.$t('Driven'),
                value: 3,
                color: 'success'
              },
              {
                title: _vm.$t('Canceled'),
                value: 4,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              {
                name: 'show'
              },
              {
                name: 'edit',
                role: _vm.$checkRole('invoices_edit') && !_vm.$option.e_invoicing
              },
              {
                name: 'file'
              },
              {
                name: 'invoiceLink',
                role: _vm.$checkRole('sale_returns_add'),
                title: _vm.$t('ReturnSale'),
                icon: 'exchange',
                link: '/saleReturns/create?invoice_id='
              },
              {
                name: 'outLink',
                role: true
              },
              {
                name: 'printInvoice',
                role: true
              },
              {
                name: 'download',
                role: true
              },
              {
                name: 'delete',
                role: _vm.$checkRole('invoices_delete') && !_vm.$option.e_invoicing
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }