<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('Purchase installments') }}
        </h4>
        <router-link
          :to="'/purchaseInstallments/create?customer_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
          v-if="$checkRole('sales_payments_add')"
        >
          <i class="fas fa-plus"></i>
          {{ $t('Addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('Find an installment')"
        :withoutCard="true"
        :filter="{ customer_id: $route.params.id }"
        :emptyTableText="$t('There are no purchase installments')"
        :emptyTableSubText="$t('Try adding some items to the table and try again')"
        :cloumns="[
          {
            column: 'code',
            title: $t('batch'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'customer_id',
            title: $t('Customer name'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: true
          },
          {
            column: 'created_date',
            title: $t('Registration date'),
            type: 'text',
            sort: true
          },
          {
            column: 'date',
            title: $t('Payment date'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: $t('Thats about'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: $t('Amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'payment_method_id',
            title: $t('Payment method'),
            type: 'link',
            to: 'paymentMethod',
            sort: true,
            link: false
          },
          {
            column: 'stat',
            title: $t('Active'),
            type: 'status',
            sort: true,
            values: [
              {
                title: $t('Waiting'),
                value: 0,
                color: 'info'
              },
              {
                title: $t('Driven'),
                value: 1,
                color: 'success'
              }
            ]
          },
          {
            column: 'options',
            title: $t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $checkRole('sales_payments_edit')
              },
              {
                name: 'pay',
                role: $checkRole('sales_payments_edit')
              },
              {
                name: 'delete',
                role: $checkRole('sales_payments_delete')
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $t('Attention'),
          areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
          close: $t('Close'),
          confirm: $t('Confirm'),
          loading: $t('Loading')
        }"
      />
    </div>
  </div>
</template>

<script>
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/purchaseInstallments'
    }
  },
  components: {
    indexTable
  }
}
</script>
