<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('salesOrders')"
          :description="$t('fromHereYouCanControlSalesOrders')"
        />
        <indexTable
          :searchText="$t('searchForAsalesOrder')"
          :emptyTableText="$t('thereAreNoOrders')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('orders_add')"
          :statusSearch="true"
          :selectAll="true"
          :statusRole="$checkRole('orders_allow_status')"
          :deleteAllRole="$checkRole('orders_delete')"
          :statusValues="[
            { title: $t('Situation'), value: '' },
            { title: $t('All'), value: '' },
            { title: $t('NewOrder'), value: 0 },
            { title: $t('BeingProcessed'), value: 1 },
            { title: $t('readyForShipping'), value: 2 },
            { title: $t('UnderShipping'), value: 3 },
            { title: $t('shipped'), value: 4 },
            { title: $t('complete'), value: 5 },
            { title: $t('returnd'), value: 6 },
            { title: $t('Canceled'), value: 7 }
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('salesOrder'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'date',
              title: $t('orderDate'),
              type: 'text',
              sort: true
            },
            {
              column: 'client_type',
              title: $t('Customer name'),
              type: 'clintLink',
              sort: false
            },
            {
              column: 'total',
              title: $t('Cost'),
              type: 'text',
              sort: true
            },
            {
              column: 'status',
              title: $t('Situation'),
              type: 'status',
              sort: true,
              values: [
                { title: $t('NewOrder'), value: 0, color: 'dark' },
                { title: $t('BeingProcessed'), value: 1, color: 'info' },
                {
                  title: $t('readyForShipping'),
                  value: 2,
                  color: 'info'
                },
                { title: $t('UnderShipping'), value: 3, color: 'info' },
                { title: $t('shipped'), value: 4, color: 'success' },
                { title: $t('complete'), value: 5, color: 'success' },
                { title: $t('returnd'), value: 6, color: 'danger' },
                { title: $t('Canceled'), value: 7, color: 'danger' }
              ]
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'edit', role: $checkRole('orders_edit') },
                { name: 'file' },
                { name: 'stat', role: $checkRole('orders_edit') },
                { name: 'printOrder', role: true },
                { name: 'printDelevary', role: true },
                {
                  name: 'delete',
                  role: $checkRole('orders_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/orders'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
