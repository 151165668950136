<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Add Partner settlement')"
          :description="$t('From here you can create a new partner settlement')"
          v-if="$route.name == 'partnersSettlementsCreate'"
        />
        <addHeader
          :title="$t('Partner settlement adjustment')"
          :description="$t('From Here You Can Modify TheSales Payment Information')"
          v-if="$route.name == 'partnersSettlementsEdit'"
        />

        <div class="mb-4 row">
          <connectionInput
            :title="$t('Numbering the process')"
            :group="$t('Groups')"
            v-model="item.invoice_group"
            :disabled="$checkRole('partners_settlements_edit_item') ? false : true"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'partnersSettlementsCreate'"
            :hasErorr="errors.invoice_group"
            :error="$t('NumberingIsRequired')"
          />
          <formInput
            :title="$t('Payment number')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'partnersSettlementsEdit'"
            maxlength="255"
          />
          <subFileInput
            :title="$t('Invoice file')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('Choose a suitable file')"
          />
          <formInput :title="$t('Reference')" v-model="item.refrance" maxlength="255" />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('Payment date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('Choose time and date')"
                  :button-now-translation="$t('Now')"
                  v-model="item.date"
                  :disabled="$checkRole('partners_settlements_edit_item') ? false : true"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <searchGroupInput
            :type="'project'"
            :values="$database.projects"
            :inputs="[{ title: $t('Project'), show: 'name' }]"
            v-if="$site.projects_allow"
          />

          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('Project output')"
            :group="$t('Project outputs')"
            v-model="item.project_item_id"
            :values="$database.projectItems.filter((el) => el.project_id == item.project_id)"
            v-if="$site.projects_allow"
          />

          <monyInput
            :title="$t('Settlement amount')"
            :disabled="true"
            v-model="item.total"
            :hasErorr="errors.total"
            :error="$t('The amount received must be greater than')"
          />

          <selectInput
            :title="$t('Situation')"
            v-model="item.status"
            :values="[
              { name: $t('Draft'), value: 0 },
              { name: $t('Approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('Partner information')"
            :description="$t('Partner information for the process')"
          />
          <productsTable class="col-12" />

          <dvider :title="$t('Payment notes')" :description="$t('Payment notes details')" />

          <formTextarea :title="$t('That about')" v-model="item.description" />
          <formTextarea :title="$t('Note')" v-model="item.notes" />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersSettlementsCreate' && !$parent.stopEdit"
          >
            {{ $t('Add sales payment') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersSettlementsEdit' && !$parent.stopEdit"
          >
            {{ $t('Sales payment adjustment') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
    <addPaymentMethod v-if="model == 'addPaymentMethod'" />
    <addPaymentFee v-if="model == 'addPaymentFee'" />
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import subFileInput from '@/elements/add/form/subFileInput.vue'

import productsTable from '@/elements/add/form/projectsSettlement/productsTable.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  data() {
    return {
      path: '/partnersSettlements',
      item: {
        invoice_group: this.$option.partners_settlements_invoice_group_id,
        date: '',
        quotationItems: [],
        cost: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        status: 1
      },
      model: false,
      errors: {
        invoice_group: false
      }
    }
  },
  mounted() {
    this.$updateDatabase([
      'partners',
      'invoiceGroups',
      'safes',
      'paymentMethods',
      'projects',
      'projectItems'
    ])

    if (this.$route.name == 'partnersSettlementsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      if (this.$route.params.id) {
        var safe = this.$database.safes.find((el) => el.id == this.$route.params.id)
        this.item.safe_id = this.$route.params.id
      }
    }
    if (this.$route.name == 'partnersSettlementsCreate') {
      this.addQuotationItem()
      this.item.date = this.$nowDate()
    }
    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        var data = response.data
        data.quotationItems.forEach((item) => {
          item.percent = (item.cost / data.total) * 100
        })
        this.item = data
        if (data.quotationItems.length == 0) {
          this.addQuotationItem()
        }

        this.$parent.stopEdit = false
        this.item.date = this.$dateFormat(this.item.date, true)
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.total === 'undefined' || this.item.total == '' || this.item.total == 0) {
        error = 1
        this.errors.total = true
      }
      if (
        (typeof this.item.invoice_group === 'undefined' ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == '' ||
          this.item.invoice_group == null) &&
        this.$route.name == 'partnersSettlementsCreate'
      ) {
        error = 1
        this.errors.invoice_group = true
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == 'partnersSettlementsCreate') {
          this.$parent.aletText = this.$t('Partners settlement successfully added')
          this.$parent.alertType = 'success'
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
          this.$parent.stopEdit = false
        } else if (this.$route.name == 'partnersSettlementsEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('Sales payment has been modified successfully')
              this.$parent.alertType = 'success'
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$parent.aletText = this.$t('Please make sure of the input')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    addQuotationItem() {
      this.item.quotationItems.push({
        id: '',
        partner_name: '',
        partner_id: 0,
        cost: '',
        percent: 0
      })
    },
    changePrice() {
      this.item.total = 0
      this.item.quotationItems.forEach((qitem) => {
        this.item.total += qitem.cost - 0
      })
      this.item.quotationItems.forEach((qitem) => {
        if (this.item.total != 0) {
          qitem.percent = (qitem.cost / this.item.total) * 100
        } else {
          qitem.percent = 0
        }
      })
    }
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    loading,

    productsTable,

    VueCtkDateTimePicker,
    searchGroupInput,
    subFileInput
  }
}
</script>
