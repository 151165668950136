<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create store')"
          :description="$t('From here you can create a new store')"
          v-if="$route.name == 'storesCreate'"
        />
        <addHeader
          :title="$t('Edit store')"
          :description="$t('From here you can modify the product store information')"
          v-if="$route.name == 'storesEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('Basic information of the store')"
            noLine="true"
          />
          <formInput
            :title="$t('The name of the Store')"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('This field is required')"
            maxlength="255"
          />
          <checkInput :title="$t('Store status')" :value="item.stat" v-model="item.stat" />
          <formTextarea :title="$t('Description of the store')" v-model="item.description" />
          <dvider :title="$t('Address of store')" :description="$t('The address of the store')" />
          <formInput :title="$t('Title')" v-model="item.address1" maxlength="255" />
          <formInput :title="$t('Supplement')" v-model="item.address2" maxlength="255" />
          <formInput :title="$t('The entity')" v-model="item.entity" maxlength="255" />
          <formInput :title="$t('City')" v-model="item.city" maxlength="255" />
          <formInput :title="$t('Postal code')" v-model="item.zip" maxlength="255" />
          <formInput :title="$t('The state')" v-model="item.country" maxlength="255" />
          <!-- Divider -->
          <hr class="mt-5 mb-5 col-12" />
          <!-- Buttons -->
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'storesCreate' && !$parent.stopEdit"
          >
            {{ $t('Create store') }}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'storesEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit store') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
export default {
  data() {
    return {
      path: '/stores',
      item: {},
      errors: {
        name: false
      }
    }
  },
  methods: {
    getItem() {
      var item = this.$database.stores.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      this.$parent.stopEdit = false
    },
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (error == 0) {
        if (this.$route.name == 'storesCreate') {
          this.$parent.aletText = this.$t('Store successfully added')
          this.$parent.alertType = 'success'
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
        } else if (this.$route.name == 'storesEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('Store has been modified successfully')
              this.$parent.alertType = 'success'
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$parent.aletText = this.$t('Please make sure of the input')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },
  mounted() {
    if (this.$route.name == 'storesEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }

    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  components: {
    formInput,
    dvider,
    addHeader,
    formTextarea,
    loading,
    checkInput
  }
}
</script>
<style></style>
