<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Add asset')"
          :description="$t('From here you can create a new asset')"
          v-if="$route.name == 'capitalsCreate'"
        />
        <addHeader
          :title="$t('Edit Asset')"
          :description="$t('From here you can modify the asset information')"
          v-if="$route.name == 'capitalsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('Assets basic information')"
            noLine="true"
          />
          <formInput
            :title="$t('Customer name')"
            :checkError="'nameErorr'"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('This field Is required')"
            maxlength="255"
          />
          <monyInput :title="$t('Purchase price')" v-model="item.cost" />

          <formInput
            type="number"
            :title="$t('Depreciation rate')"
            v-model="item.depreciation_rate"
            maxlength="255"
          />

          <checkInput :title="$t('Customer case')" :value="item.stat" v-model="item.stat" />

          <dvider
            :title="$t('Asset validity period')"
            :description="$t('Enter fixed asset validity periods')"
          />

          <div class="form-group row col-md-6">
            <label class="col-md-4">{{ $t('Purchase date') }}</label>
            <div class="col-md-7 icon-input w-100">
              <span><i class="far fa-calendar"></i></span>
              <VueCtkDateTimePicker
                :label="$t('Choose time and date')"
                :button-now-translation="$t('Now')"
                v-model="item.date"
                format="YYYY-MM-DD hh:mm a"
                color="#631263"
                button-color="#631263"
              />
            </div>
          </div>

          <div class="form-group row col-md-6">
            <label class="col-md-4">{{ $t('Date of sale') }}</label>
            <div class="col-md-7 icon-input w-100">
              <span><i class="far fa-calendar"></i></span>
              <VueCtkDateTimePicker
                :label="$t('Choose time and date')"
                :button-now-translation="$t('Now')"
                v-model="item.expiry_date"
                format="YYYY-MM-DD hh:mm a"
                color="#631263"
                button-color="#631263"
              />
            </div>
          </div>

          <dvider
            :title="$t('Additional information')"
            :description="$t('Enter the details of the fixed asset')"
          />

          <formTextarea :title="$t('Description of the original')" v-model="item.descripton" />

          <formTextarea :title="$t('Other detailed data')" v-model="item.details" />

          <formTextarea :title="$t('Reviews')" v-model="item.notes" />

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'capitalsCreate' && !$parent.stopEdit"
          >
            {{ $t('Add Fixed asset') }}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'capitalsEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit Fixed asset') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  data() {
    return {
      path: '/capitals',
      item: {
        stat: 1,
        date: '',
        expiry_date: ''
      },
      errors: {
        name: false
      }
    }
  },
  methods: {
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (error == 0) {
        if (this.$route.name == 'capitalsCreate') {
          this.$parent.aletText = this.$t('Asset created successfully')
          this.$parent.alertType = 'success'
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
        } else if (this.$route.name == 'capitalsEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('Asset has been Modified Successfully')
              this.$parent.alertType = 'success'
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$parent.aletText = this.$t('Please make sure of the input')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    getItem() {
      var item = this.$database.capitals.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      this.item.date = this.$dateFormat(this.item.date, true)
      this.item.expiry_date = this.$dateFormat(this.item.expiry_date, true)
      this.$parent.stopEdit = false
    }
  },
  mounted() {
    if (this.$route.name == 'capitalsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      var date = new Date()
      this.item.date = date.toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0, 10)
      date.setDate(date.getDate() + 5)
      this.item.expiry_date = date.toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0, 10)
    }
  },
  components: {
    formInput,
    formTextarea,
    dvider,
    addHeader,
    monyInput,
    checkInput,
    loading,
    VueCtkDateTimePicker
  }
}
</script>
<style></style>
