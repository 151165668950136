<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('Invoices') }}
        </h4>
        <router-link
          class="btn btn-sm btn-primary"
          :to="'/saleReturns/create?customer_id=' + $route.params.id"
          v-if="$parent.item.stat == 1 && $checkRole('invoices_add')"
        >
          <i class="fas fa-plus"></i>
          {{ $t('Addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('Search for an invoice')"
        :withoutCard="true"
        :emptyTableText="$t('There are no bills')"
        :filter="{ customer_id: $route.params.id }"
        :emptyTableSubText="$t('Try adding some items to the table and try again')"
        :statusSearch="true"
        :statusValues="[
          {
            title: $t('Situation'),
            value: ''
          },
          { title: $t('All'), value: '' },
          { title: $t('Draft'), value: 0 },
          { title: $t('Sent'), value: 1 },
          { title: $t('Seen'), value: 2 },
          { title: $t('Driven'), value: 3 },
          { title: $t('Canceled'), value: 4 }
        ]"
        :cloumns="[
          {
            column: 'code',
            title: $t('Invoice'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: $t('The date of the invoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'customer_id',
            title: $t('Customer name'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: true
          },
          {
            column: 'total',
            title: $t('Cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'rest',
            title: $t('Balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: $t('Situation'),
            type: 'status',
            sort: true,
            values: [
              {
                title: $t('Draft'),
                value: 0,
                color: 'dark'
              },
              {
                title: $t('Sent'),
                value: 1,
                color: 'primary'
              },
              {
                title: $t('Seen'),
                value: 2,
                color: 'primary'
              },
              {
                title: $t('Driven'),
                value: 3,
                color: 'success'
              },
              {
                title: $t('Canceled'),
                value: 4,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: $t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $checkRole('sale_returns_edit')
              },
              { name: 'outLink', role: true },
              { name: 'printSaleReturn', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: $checkRole('sale_returns_delete')
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $t('Attention'),
          areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
          close: $t('Close'),
          confirm: $t('Confirm'),
          loading: $t('Loading')
        }"
      />
    </div>
  </div>
</template>

<script>
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/saleReturns'
    }
  },
  components: {
    indexTable
  }
}
</script>
