<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{ $t('expenses') }}</h4>
        <router-link
          :to="'/expenses/create?safe_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
          v-if="$parent.item.stat == 1 && $checkRole('sales_payments_add')"
        >
          <i class="fas fa-plus"></i> {{ $t('Addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('searchForMoney')"
        :emptyTableText="$t('There are no expenses')"
        :filter="{ safe_id: $route.params.id }"
        :withoutCard="true"
        :emptyTableSubText="$t('Try adding some items to the table and try again')"
        :cloumns="[
          {
            column: 'code',
            title: $t('The expense'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'safe_id',
            title: $t('Treasury'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: $t('Expense date'),
            type: 'text',
            sort: true
          },
          {
            column: 'expense_section_id',
            title: $t('section'),
            type: 'link',
            to: 'expenseSection',
            sort: true,
            link: false
          },
          {
            column: 'description',
            title: $t('Thats about'),
            type: 'text',
            sort: true
          },
          {
            column: 'notes',
            title: $t('Note'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: $t('Amount'),
            type: 'text',
            sort: true
          },

          {
            column: 'options',
            title: $t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $checkRole('expenses_edit')
              },
              { name: 'print', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: $checkRole('expenses_delete')
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $t('Attention'),
          areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
          close: $t('Close'),
          confirm: $t('Confirm'),
          loading: $t('Loading')
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/expenses'
    }
  },
  components: {
    indexTable
  }
}
</script>
