<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Employees')"
          :description="$t('From here you can control employees')"
        />
        <indexTable
          :searchText="$t('Find an employee')"
          :emptyTableText="$t('There are no employees')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('employees_add')"
          :cloumns="[
            {
              column: 'name',
              title: $t('Name'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'job_title',
              title: $t('Job title'),
              type: 'text',
              sort: true,
              icon: 'user-tie'
            },
            {
              column: 'salary',
              title: $t('Salary'),
              type: 'text',
              sort: true,
              icon: 'coins'
            },
            {
              column: 'balance',
              title: $t('Balance'),
              type: 'text',
              sort: true,
              icon: 'usd-square'
            },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'stat',
              sort: true,
              hideMobile: true
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $checkRole('employees_edit')
                },
                {
                  name: 'link',
                  role: $checkRole('employees_edit'),
                  title: $t('Add a salary'),
                  icon: 'money-check-alt',
                  link: '/employeesSalaries/create?employee_id='
                },
                {
                  name: 'link',
                  role: $checkRole('employees_edit'),
                  title: $t('Incentive'),
                  icon: 'hand-holding-usd',
                  link: '/employeesIncentives/create?employee_id='
                },
                {
                  name: 'link',
                  role: $checkRole('employees_edit'),
                  title: $t('Deduct'),
                  icon: 'funnel-dollar',
                  link: '/employeesDeductions/create?employee_id='
                },
                {
                  name: 'link',
                  role: $checkRole('employees_edit'),
                  title: $t('Balance withdrawal'),
                  icon: 'dollar-sign',
                  link: '/employeesWithdrawals/create?employee_id='
                },
                {
                  name: 'delete',
                  role: $checkRole('employees_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/employees'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
