<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Sales cheques')"
          :description="$t('From here you can control the sales cheques')"
        />
        <indexTable
          :searchText="$t('Find a cheque')"
          :emptyTableText="$t('There Are No sales cheques')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('sales_cheques_add')"
          :statusSearch="true"
          :statusValues="[
            { title: $t('Active'), value: '' },
            { title: $t('Waiting'), value: 0 },
            {
              title: $t('Driven'),
              value: 1
            }
          ]"
          :cloumns="[
            { column: 'code', title: $t('batch'), type: 'mainLink', sort: true },
            {
              column: 'client_type',
              title: $t('Customer name'),
              type: 'clintLink',
              sort: true
            },
            {
              column: 'safe_id',
              title: $t('Treasury'),
              type: 'link',
              to: 'safe',
              sort: true,
              link: true
            },
            {
              column: 'created_date',
              title: $t('Registration date'),
              type: 'text',
              sort: true
            },
            { column: 'date', title: $t('Payment date'), type: 'text', sort: true },
            {
              column: 'description',
              title: $t('Thats about'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: $t('Amount'),
              type: 'text',
              sort: true
            },
            { column: 'user_name', title: $t('Responsible name'), type: 'text', sort: true },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'status',
              sort: true,
              values: [
                { title: $t('Waiting'), value: 0, color: 'info' },
                { title: $t('Driven'), value: 1, color: 'success' },
                { title: $t('Converted'), value: 2, color: 'success' },
                { title: $t('Refused'), value: 3, color: 'danger' }
              ]
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'printSalesCheque', role: true },
                {
                  name: 'paySalesCheque',
                  role: $checkRole('sales_cheques_edit')
                },
                { name: 'edit', role: $checkRole('sales_cheques_edit') },
                { name: 'delete', role: $checkRole('sales_cheques_delete') }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/salesCheques'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
