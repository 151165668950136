var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('salesOrders'),"description":_vm.$t('fromHereYouCanControlSalesOrders')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('searchForAsalesOrder'),"emptyTableText":_vm.$t('thereAreNoOrders'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('orders_add'),"statusSearch":true,"selectAll":true,"statusRole":_vm.$checkRole('orders_allow_status'),"deleteAllRole":_vm.$checkRole('orders_delete'),"statusValues":[
          { title: _vm.$t('Situation'), value: '' },
          { title: _vm.$t('All'), value: '' },
          { title: _vm.$t('NewOrder'), value: 0 },
          { title: _vm.$t('BeingProcessed'), value: 1 },
          { title: _vm.$t('readyForShipping'), value: 2 },
          { title: _vm.$t('UnderShipping'), value: 3 },
          { title: _vm.$t('shipped'), value: 4 },
          { title: _vm.$t('complete'), value: 5 },
          { title: _vm.$t('returnd'), value: 6 },
          { title: _vm.$t('Canceled'), value: 7 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('salesOrder'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('orderDate'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('Customer name'),
            type: 'clintLink',
            sort: false
          },
          {
            column: 'total',
            title: _vm.$t('Cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('Situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('NewOrder'), value: 0, color: 'dark' },
              { title: _vm.$t('BeingProcessed'), value: 1, color: 'info' },
              {
                title: _vm.$t('readyForShipping'),
                value: 2,
                color: 'info'
              },
              { title: _vm.$t('UnderShipping'), value: 3, color: 'info' },
              { title: _vm.$t('shipped'), value: 4, color: 'success' },
              { title: _vm.$t('complete'), value: 5, color: 'success' },
              { title: _vm.$t('returnd'), value: 6, color: 'danger' },
              { title: _vm.$t('Canceled'), value: 7, color: 'danger' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$checkRole('orders_edit') },
              { name: 'file' },
              { name: 'stat', role: _vm.$checkRole('orders_edit') },
              { name: 'printOrder', role: true },
              { name: 'printDelevary', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('orders_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }