var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Users'),"description":_vm.$t('from Here You Can Control Users')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('search For A User'),"emptyTableText":_vm.$t('there Are No Users'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('users_add'),"localData":true,"cloumns":[
          {
            column: 'name',
            title: _vm.$t('name'),
            type: 'text',
            sort: true
          },
          { column: 'role_name', title: _vm.$t('userType'), type: 'text' },
          {
            column: 'email',
            title: _vm.$t('email'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'edit', role: _vm.$checkRole('users_edit') },
              {
                name: 'delete',
                role: _vm.$checkRole('users_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }