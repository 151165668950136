<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :image="item.image"
        :icon="'user'"
        :title="item.name"
        :stat="item.stat"
        :statText="item.stat ? $t('Active') : $t('Inactive')"
        :subData="[
          {
            title: $t('tax identification number'),
            value: item.tax_number
          }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('customers_edit')
          },
          {
            title: $t('Comprehensive report'),
            icon: 'file-chart-pie',
            link: '/customersDetailsAccounts?customer_id=' + $route.params.id,
            role: $checkRole('reports_show')
          },
          {
            title: $t('Add a batch'),
            icon: 'dollar-sign',
            link: '/salesPayments/create?customer_id=' + $route.params.id,
            role: $checkRole('sales_payments_add')
          },
          {
            title: $t('Add a bid'),
            icon: 'calculator',
            link: '/quotations/create?customer_id=' + $route.params.id,
            role: $checkRole('quotations_add')
          },
          {
            title: $t('Add a sales bill'),
            icon: 'file-invoice-dollar',
            link: '/invoices/create?customer_id=' + $route.params.id,
            role: $checkRole('invoices_add')
          },
          {
            title: $t('Add a request'),
            icon: 'briefcase',
            link: '/orders/create?customer_id=' + $route.params.id,
            role: $checkRole('orders_add')
          }
        ]"
      />

      <div class="col-12">
        <showFile />
      </div>

      <showCard
        class="col-12"
        :title="$t('Background')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'user',
              title: $t('Customer name'),
              value: item.name
            },
            {
              icon: 'dollar-sign',
              title: $t('Balance'),
              value: $moneyFormat($option.balance_type === 1 ? item.balance * -1 : item.balance)
            }
          ],
          scondCloum: [
            {
              icon: 'dollar-sign',
              title: $t('Opening balance'),
              value: $moneyFormat(item.opening_balance)
            },
            {
              icon: 'toggle-on',
              title: $t('Customer status'),
              value: item.stat === 1 ? $t('effective') : $t('ineffective')
            }
          ]
        }"
      />

      <showCard
        class="col-12"
        :title="$t('Billing address')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Address'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('Governorate'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Zip code'),
              value: item.zip
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Itinerary'),
              value: item.address2
            },
            {
              icon: 'city',
              title: $t('City'),
              value: item.city
            },
            {
              icon: 'globe-americas',
              title: $t('State'),
              value: item.country
            }
          ]
        }"
      />

      <showCard
        class="col-12"
        :title="$t('Communication information')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'phone',
              title: $t('Mobile number'),
              value: item.mobile2 ? item.mobile + ' - ' + item.mobile2 : item.mobile
            }
          ],
          scondCloum: [
            {
              icon: 'at',
              title: $t('Email'),
              value: item.email
            }
          ]
        }"
      />

      <invoices class="col-12" v-if="$checkRole('invoices_show')" />
      <bills class="col-12" v-if="$checkRole('bills_show')" />
      <salesReturns class="col-12" v-if="$checkRole('sale_returns_show')" />
      <purchaseReturns class="col-12" v-if="$checkRole('purchase_returns_show')" />
      <salesPayments class="col-12" v-if="$checkRole('sales_payments_show')" />
      <purchasePayement class="col-12" v-if="$checkRole('purchase_payments_show')" />
      <salesCheques class="col-12" v-if="$checkRole('sales_cheques_show')" />
      <purchaseCheques class="col-12" v-if="$checkRole('purchase_cheques_show')" />
      <salesDiscounts class="col-12" v-if="$checkRole('sales_discounts_show')" />
      <purchaseDiscounts class="col-12" v-if="$checkRole('purchase_discounts_show')" />
      <salesInstallments class="col-12" v-if="$checkRole('sales_installments_show')" />
      <purchaseInstallments class="col-12" v-if="$checkRole('purchase_installments_show')" />

      <notes class="col-12 col-xl-7" />
      <contacts class="col-12 col-lg-5" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import notes from './components/notes.vue'
import contacts from './components/contacts/index.vue'
import invoices from './components/invoices.vue'
import bills from './components/bills.vue'
import salesReturns from './components/salesReturns.vue'
import purchaseReturns from './components/purchaseReturns.vue'
import salesPayments from './components/salesPayments.vue'
import purchasePayement from './components/purchasepayement.vue'
import salesCheques from './components/salesCheques.vue'
import purchaseCheques from './components/purchaseCheques.vue'
import salesDiscounts from './components/salesDiscounts.vue'
import purchaseDiscounts from './components/purchaseDiscounts.vue'
import salesInstallments from './components/salesInstallments.vue'
import purchaseInstallments from './components/purchaseInstallments.vue'

import showFile from '@/elements/show/showFile.vue'

export default {
  data() {
    return {
      path: '/customers',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      var item = this.$database.customers.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
      })
    }
  },
  components: {
    showHeader,
    showCard,
    notes,
    contacts,
    invoices,
    bills,
    salesReturns,
    purchaseReturns,
    salesPayments,
    purchasePayement,
    salesCheques,
    purchaseCheques,
    salesDiscounts,
    purchaseDiscounts,
    salesInstallments,
    purchaseInstallments,
    showFile
  }
}
</script>
