var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Purchase cheques'),"description":_vm.$t('From here you can control the purchase cheques')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Find a Cheque'),"emptyTableText":_vm.$t('there are no purchase cheques'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('purchase_cheques_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('Active'), value: '' },
          { title: _vm.$t('Waiting'), value: 0 },
          { title: _vm.$t('Driven'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('batch'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('Supplier name'),
            type: 'clintLink',
            sort: true
          },
          {
            column: 'safe_id',
            title: _vm.$t('Treasury'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'created_date',
            title: _vm.$t('Registration date'),
            type: 'text',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('Payment date'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('Thats about'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: _vm.$t('Amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'payment_method_id',
            title: _vm.$t('Payment method'),
            type: 'link',
            to: 'paymentMethod',
            sort: true,
            link: false
          },
          {
            column: 'user_name',
            title: _vm.$t('Responsible name'),
            type: 'text',
            sort: true
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'status',
            sort: true,
            values: [
              {
                title: _vm.$t('Waiting'),
                value: 0,
                color: 'info'
              },
              {
                title: _vm.$t('Driven'),
                value: 1,
                color: 'success'
              },
              {
                title: _vm.$t('Refused'),
                value: 2,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'printPurchaseCheque', role: true },
              {
                name: 'edit',
                role: _vm.$checkRole('purchase_cheques_edit')
              },
              {
                name: 'payPurchaseCheque',
                role: _vm.$checkRole('purchase_cheques_edit')
              },
              { name: 'file' },
              {
                name: 'delete',
                role: _vm.$checkRole('purchase_cheques_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }