var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('invoices'))+" ")]),(_vm.$parent.item.stat == 1 && _vm.$checkRole('invoices_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/saleReturns/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('Addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$parent.$t('Search for an invoice'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('There are no bills'),"filter":{ customer_id: _vm.$route.params.id },"emptyTableSubText":_vm.$parent.$t('Try adding some items to the table and try again'),"statusSearch":true,"statusValues":[
        {
          title: _vm.$parent.$t('Situation'),
          value: ''
        },
        {
          title: _vm.$parent.$t('All'),
          value: ''
        },
        {
          title: _vm.$parent.$t('draft'),
          value: 0
        },
        {
          title: _vm.$parent.$t('Sent'),
          value: 1
        },
        {
          title: _vm.$parent.$t('Seen'),
          value: 2
        },
        {
          title: _vm.$parent.$t('Driven'),
          value: 3
        },
        {
          title: _vm.$parent.$t('Canceled'),
          value: 4
        }
      ],"cloumns":[
        {
          column: 'code',
          title: _vm.$parent.$t('invoice'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'date',
          title: _vm.$parent.$t('The date of the invoice'),
          type: 'text',
          sort: true
        },
        {
          column: 'customer_id',
          title: _vm.$parent.$t('Customer name'),
          type: 'link',
          to: 'customer',
          sort: true,
          link: true
        },
        {
          column: 'total',
          title: _vm.$parent.$t('Cost'),
          type: 'text',
          sort: true
        },
        {
          column: 'rest',
          title: _vm.$parent.$t('Balance'),
          type: 'text',
          sort: true
        },
        {
          column: 'status',
          title: _vm.$parent.$t('Situation'),
          type: 'status',
          sort: true,
          values: [
            {
              title: _vm.$parent.$t('draft'),
              value: 0,
              color: 'dark'
            },
            {
              title: _vm.$parent.$t('Sent'),
              value: 1,
              color: 'primary'
            },
            {
              title: _vm.$parent.$t('Seen'),
              value: 2,
              color: 'primary'
            },
            {
              title: _vm.$parent.$t('Driven'),
              value: 3,
              color: 'success'
            },
            {
              title: _vm.$parent.$t('Canceled'),
              value: 4,
              color: 'danger'
            }
          ]
        },
        {
          column: 'options',
          title: _vm.$parent.$t('Settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('sale_returns_edit')
            },
            { name: 'outLink', role: true },
            { name: 'printSaleReturn', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role: _vm.$checkRole('sale_returns_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$parent.$t('Attention'),
        areYouReallySureToDeleteTheItem: _vm.$parent.$t('Are you really sure to delete the item'),
        close: _vm.$parent.$t('Close'),
        confirm: _vm.$parent.$t('Confirm'),
        loading: _vm.$parent.$t('Loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }