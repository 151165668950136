<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Payment classifications')"
          :description="$t('From Here You Can Control The Classifications Of Payments')"
        />

        <indexTable
          :searchText="$t('Search For A Rating')"
          :emptyTableText="$t('There Are No Ratings')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('payments_add')"
          :cloumns="[
            {
              column: 'name',
              title: $t('Section name'),
              type: 'text',
              sort: true
            },
            {
              column: 'payment_section_id',
              title: $t('Father section'),
              type: 'link',
              to: 'paymentSection',
              sort: true,
              link: false
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'edit', role: $checkRole('expenses_edit') },
                {
                  name: 'delete',
                  role: $checkRole('expenses_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/PaymentsSections',
      items: [],

      delete_id: null,
      totalPages: 0,
      loading: 0,

      params: {}
    }
  },
  methods: {
    getItems() {
      var params = this.params
      this.$setParams(params)
      var items = JSON.parse(JSON.stringify(this.$database.PaymentsSections))
      var localItems = JSON.parse(JSON.stringify(this.$parent.localDatabase.PaymentsSections))
      items = localItems.concat(items)
      if (params.orderBy) {
        items = items.sort(this.$dynamicSort(params))
      }
      var editsearch = new RegExp(this.params.search, 'i')
      items = items.filter((el) => editsearch.test(el.name))

      this.totalPages = Math.ceil(items.length / 15)
      this.items = items.slice(this.params.page * 15 - 15, this.params.page * 15)

      this.items.forEach((item) => {
        var expenseSection = this.$database.find((el) => el.id == item.expense_section_id)
        item.expenseSection = expenseSection
      })
    },
    deleteItem() {
      axios
        .post(this.$linkGenerator(this.path + '/' + this.items[this.delete_id].id), {
          _method: 'DELETE'
        })
        .then((response) => {
          this.$updateDatabase(['PaymentsSections'])
          this.delete_id = null
          this.$parent.model = 0
          return response
        })
    }
  },
  watch: {
    '$database.PaymentsSections': function () {
      this.getItems()
    },
    '$parent.localDatabase.PaymentsSections': function () {
      this.$updateDatabase(['PaymentsSections'])
    },
    'params.search': function () {
      this.params.page = 1
      this.getItems()
    }
  },
  mounted() {
    this.$updateDatabase(['PaymentsSections'])
    this.params = this.$getParams()
    if (!this.params.page) {
      this.params.page = 1
    }
    this.getItems()
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
