var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Stores loading requests'),"description":_vm.$t('From here you can control stores loading operations')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for stores loading operations'),"emptyTableText":_vm.$t('There are no store loading operations!'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('stores_orders_show'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('Situation'), value: '' },
          { title: _vm.$t('All'), value: '' },
          { title: _vm.$t('Pending'), value: 0 },
          { title: _vm.$t('Loaded'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('Operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'store_id',
            title: _vm.$t('Store name'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('The date of the operation'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('Description'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: _vm.$t('Responsible name'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('Situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('Pending'), value: 0, color: 'dark' },
              { title: _vm.$t('Loaded'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('stores_orders_edit')
              },
              {
                name: 'approveMoveStore',
                role: _vm.$checkRole('stores_transfers_add'),
                title: _vm.$t('Confirm loading'),
                icon: 'suitcase'
              },

              {
                name: 'delete',
                role: _vm.$checkRole('stores_orders_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }