var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Purchases to sales payments Reports')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Supplier name'),"plaseholder":_vm.$t('Choose supplier name'),"show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.$database.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('section supplier'),"plaseholder":_vm.$t('choose Section'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Purchases to sales payments Reports'),"details":[
            { title: 'اسم المورد', value: _vm.supplierName },
            {
              title: _vm.$t('section supplier'),
              value: _vm.sectionCustomerName
            }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'name',
              link: '/suppliers',
              title: _vm.$t('Supplier name'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'burchase',
              title: _vm.$t('Purchases'),
              type: 'text',
              sort: true
            },
            {
              column: 'payments',
              title: _vm.$t('Sales Payments'),
              type: 'text',
              sort: true
            },
            { column: 'balance', title: _vm.$t('Balance'), type: 'text', sort: true }
          ],"footer":[
            { colspan: 1, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.burchase },
            { colspan: 1, value: _vm.payments },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }