<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create a partner')"
          :description="$t('That is where you can set up a new partner')"
          v-if="$route.name == 'partnersCreate'"
        />
        <addHeader
          :title="$t('Partner modification')"
          :description="$t('From here you can edit partner data')"
          v-if="$route.name == 'partnersEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Background')"
            :description="$t('Partner background information')"
            noLine="true"
          />

          <fileInput
            :title="$t('Partner photo')"
            v-model="item.image"
            :image="item.image"
            :path="'partners'"
            :hasErorr="errors.image"
            :error="$t('Choose a suitable file')"
            :col="'col-12'"
          />

          <formInput
            :title="$t('Partners name')"
            :checkError="'nameErorr'"
            v-model="item.name"
            maxlength="255"
            :hasErorr="errors.name"
            :error="$t('This field is required')"
          />
          <formInput
            :title="$t('Tax identification number')"
            v-model="item.tax_number"
            maxlength="255"
          />

          <searchGroupInput
            :type="'sectionsAccount'"
            v-model="item.sections_account_id"
            :values="$database.sectionsAccounts"
            :model="'sections_account'"
            :inputs="[{ show: 'name' }]"
          />

          <checkInput :title="$t('Partner status')" :value="item.stat" v-model="item.stat" />

          <dvider
            :title="$t('Account settings')"
            :description="$t('From here you can control settings accounts')"
          />

          <selectInput
            :title="$t('Balance type')"
            v-model="item.balance_type"
            :disabled="this.$route.name == 'partnersEdit' ? true : false"
            :values="[
              { value: 0, name: $t('Cumulative') },
              { value: 1, name: $t('Creditor and Debitor') }
            ]"
          />

          <monyInput
            :title="$t('Initial balance')"
            v-model="item.balance"
            :disabled="this.$route.name == 'partnersEdit' || item.balance_type == 1"
            :checkError="'nameErorr'"
            :hasErorr="errors.balance"
            :error="$t('This field is required')"
          />

          <monyInput
            v-if="item.balance_type == 1"
            :title="$t('Debit')"
            v-model="item.debit"
            :disabled="this.$route.name == 'partnersEdit' ? true : false"
            :checkError="'nameErorr'"
            :hasErorr="errors.debit"
            :error="$t('This field is required')"
          />

          <monyInput
            v-if="item.balance_type == 1"
            :title="$t('Creditor')"
            v-model="item.creditor"
            :disabled="this.$route.name == 'partnersEdit' ? true : false"
            :checkError="'nameErorr'"
            :hasErorr="errors.creditor"
            :error="$t('This field is required')"
          />

          <checkInput
            :title="$t('KeyPartner')"
            :value="item.mainPartner"
            v-model="item.mainPartner"
          />

          <div class="form-group row col-md-6" v-if="item.mainPartner == true">
            <label class="col-md-4"> {{ $t('Partnership ratio') }} </label>
            <div class="add-input col-md-7">
              <input
                type="number"
                class="form-control"
                v-model="item.PartnershiRatio"
                :class="{ 'is-invalid': errors.rate }"
                @change="errors.rate = false"
                min="1"
                max="100"
              />
              <span><i class="far fa-percentage"></i></span>
            </div>
          </div>

          <subFileInput
            :title="$t('Invoices balance')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
          />

          <dvider
            :title="$t('Billing address')"
            :description="$t('The address for billing is recorded in the invoice')"
          />

          <formInput :title="$t('Address')" v-model="item.address1" maxlength="255" />
          <formInput :title="$t('The sequel')" v-model="item.address2" maxlength="255" />
          <formInput :title="$t('The side')" v-model="item.entity" maxlength="255" />
          <formInput :title="$t('City')" v-model="item.city" maxlength="255" />
          <formInput :title="$t('Zip code')" v-model="item.zip" maxlength="255" />
          <formInput :title="$t('State')" v-model="item.country" maxlength="255" />

          <dvider
            :title="$t('Communication information')"
            :description="$t('Partner communication information')"
          />
          <iconInput
            :type="'text'"
            :title="$t('Mobile number')"
            :icon="'phone'"
            v-model="item.mobile"
          />
          <iconInput :type="'text'" :title="$t('email')" :icon="'at'" v-model="item.email" />

          <!-- Divider -->
          <hr class="mt-5 mb-5 col-12" />
          <!-- Buttons -->
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersCreate' && !$parent.stopEdit"
          >
            {{ $t('Create partner') }}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit partner') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import fileInput from '@/elements/add/form/fileInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import iconInput from '@/elements/add/form/iconInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'
import subFileInput from '@/elements/add/form/subFileInput.vue'

export default {
  data() {
    return {
      path: '/partners',
      item: {
        stat: 1,
        mainPartner: false,
        balance_type: 1,
        sections_account: {}
      },
      errors: {
        name: false,
        balanse: false
      }
    }
  },
  methods: {
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (typeof this.item.balance !== 'undefined' && this.item.balance.length > 11) {
        error = 1
        this.errors.balance = true
      }
      if (error == 0) {
        if (this.$route.name == 'partnersCreate') {
          this.$parent.aletText = this.$t('Partner successfully added')
          this.$parent.alertType = 'success'
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
        } else if (this.$route.name == 'partnersEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              if (response.data == 'repeated') {
                this.$parent.aletText = this.$t('Partner code is duplicated')
                this.$parent.alertType = 'danger'
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
                this.$parent.stopEdit = false
                this.loading = false
              } else {
                this.$parent.aletText = this.$t('Partner has been modified successfully')
                this.$parent.alertType = 'success'
                this.$router.go(-1)
                this.$parent.stopEdit = false
                this.loading = false
              }
            })
        }
      } else {
        this.$parent.aletText = this.$t('Please make sure of the input')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    getItem() {
      var item = this.$database.partners.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
        var sectionsAccount = this.$database.sectionsAccounts.find(
          (el) => el.id == item.sections_account_id
        )
        if (sectionsAccount) {
          this.item.sections_account = sectionsAccount
        } else {
          this.item.sections_account = {}
        }
      }
      this.$parent.stopEdit = false
    },
    claculateBalance() {
      this.item.balance =
        parseFloat(this.item.creditor && this.item.creditor != '' ? this.item.creditor : 0) -
        parseFloat(this.item.debit != '' && this.item.debit ? this.item.debit : 0)
      if (this.$option.balance_type == 1) {
        this.item.balance = this.item.balance * -1
      }
    }
  },
  watch: {
    'item.name': function (val) {
      var partner = this.$database.partners.find((el) => el.name == val && el.id != this.item.id)
      if (typeof partner !== 'undefined') {
        this.$parent.aletText = this.$t('Partner name is duplicated')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    'item.tax_number': function (val) {
      var partner = this.$database.partners.find(
        (el) => el.tax_number == val && el.id != this.item.id
      )
      if (typeof partner !== 'undefined') {
        this.$parent.aletText = this.$t('Partner code is required')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    'item.debit': function () {
      this.claculateBalance()
    },
    'item.creditor': function () {
      this.claculateBalance()
    },
    'item.balance_type': function () {
      this.claculateBalance()
    }
  },
  mounted() {
    this.$updateDatabase(['partners'])
    if (this.$route.name == 'partnersEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }

    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  components: {
    formInput,
    fileInput,
    dvider,
    addHeader,
    monyInput,
    checkInput,
    iconInput,
    selectInput,
    loading,
    connectionInput,
    searchGroupInput,
    subFileInput
  }
}
</script>
