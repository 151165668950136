var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Shipping companies'),"description":_vm.$t('From Here You Can Control The Shipping Agents')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Looking For A Shipping Representative'),"emptyTableText":_vm.$t('There Are No Shipping Agents'),"localData":true,"buttonRole":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('Shipping Representatives Name'),
            type: 'text',
            sort: true
          },
          {
            column: 'safe_id',
            title: _vm.$t('Treasury'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'balance',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'edit', role: true },
              { name: 'delete', role: true }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }