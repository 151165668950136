<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :icon="'store-alt'"
        :title="item.name"
        :subData="[
          { title: $t('The state'), value: item.country },
          { title: $t('City'), value: item.city }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('stores_edit')
          },
          {
            title: $t('Inventory of the store'),
            icon: 'file-chart-pie',
            link: '/warehouseInventory?store_id=' + $route.params.id,
            role: $checkRole('reports_show')
          }
        ]"
      />
      <showCard
        class="col-12"
        :title="$t('Store address')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Address'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('The side'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Zip code'),
              value: item.zip
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('The sequel'),
              value: item.address2
            },
            { icon: 'city', title: $t('City'), value: item.city },
            {
              icon: 'globe-americas',
              title: $t('State'),
              value: item.country
            }
          ]
        }"
      />
      <invoices class="col-12" v-if="$checkRole('invoices_show')" />
      <bills class="col-12" v-if="$checkRole('bills_show')" />
      <salesReturns class="col-12" v-if="$checkRole('sale_returns_show')" />
      <purchaseReturns class="col-12" v-if="$checkRole('purchase_returns_show')" />
      <storesDeposits class="col-12" v-if="$checkRole('stores_deposits_show')" />
      <storesWithdrawals class="col-12" v-if="$checkRole('stores_withdrawals_show')" />
      <fromStoresTransfers class="col-12" v-if="$checkRole('stores_transfers_show')" />
      <toStoresTransfers class="col-12" v-if="$checkRole('stores_transfers_show')" />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'

import salesReturns from './components/salesReturns.vue'
import purchaseReturns from './components/purchaseReturns.vue'
import invoices from './components/invoices.vue'
import bills from './components/bills.vue'
import storesWithdrawals from './components/storesWithdrawals.vue'
import storesDeposits from './components/storesDeposits.vue'
import fromStoresTransfers from './components/fromStoresTransfers.vue'
import toStoresTransfers from './components/toStoresTransfers.vue'

export default {
  data() {
    return {
      path: '/stores',
      item: {}
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      var item = this.$database.stores.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    }
  },
  components: {
    showHeader,
    showCard,
    invoices,
    bills,
    salesReturns,
    purchaseReturns,
    storesWithdrawals,
    storesDeposits,
    fromStoresTransfers,
    toStoresTransfers
  }
}
</script>
<style></style>
