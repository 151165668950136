var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('manufacturingTransfers.ManufacturingTransfers'),"description":_vm.$t('manufacturingTransfers.FromHereYouCanControlManufacturingTransfers')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingTransfers.SearchForAnOperation'),"emptyTableText":_vm.$t('manufacturingTransfers.ThereAreNoTransfersWithManufacturingProcesses'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('manufacturing_transfers_add'),"cloumns":[
          {
            column: 'code',
            title: _vm.$t('manufacturingTransfers.operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'project_id',
            title: _vm.$t('manufacturingTransfers.TheNameOfTheManufacturingProcess'),
            type: 'link',
            to: 'project',
            sort: true,
            link: true
          },
          {
            column: 'from_manufacture_id',
            title: _vm.$t('manufacturingTransfers.FromAProject'),
            type: 'link',
            to: 'manufacture',
            sort: true,
            link: true
          },
          {
            column: 'to_manufacture_id',
            title: _vm.$t('manufacturingTransfers.ToAProject'),
            type: 'link',
            to: 'manufacture',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('manufacturingTransfers.theDateOfTheOperation'),
            type: 'text',
            sort: true
          },
          {
            column: 'total',
            title: _vm.$t('manufacturingTransfers.Total'),
            type: 'text',
            sort: true,
            after: _vm.$option.currency
          },
          {
            column: 'description',
            title: _vm.$t('manufacturingTransfers.thatAbout'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('manufacturingTransfers.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',

                role: _vm.$checkRole('manufacturing_transfers_edit')
              },
              { name: 'printprojectsWithdrawal', role: true },
              {
                name: 'delete',

                role: _vm.$checkRole('manufacturing_transfers_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }