<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Sales discounts')"
          :description="$t('From here you can control the sales discounts')"
        />
        <indexTable
          :searchText="$t('Find a discount')"
          :emptyTableText="$t('There are no sales discounts')"
          :buttonRole="$checkRole('sales_discounts_add')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :statusSearch="true"
          :statusValues="[
            { title: $t('Situation'), value: '' },
            { title: $t('All'), value: '' },
            { title: $t('Draft'), value: 0 },
            { title: $t('Approved'), value: 1 }
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('Discount'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'client_type',
              title: $t('Customer name'),
              type: 'clintLink',
              sort: true
            },
            {
              column: 'date',
              title: $t('Date'),
              type: 'text',
              sort: true
            },
            {
              column: 'description',
              title: $t('Thats about'),
              type: 'text',
              sort: true
            },

            {
              column: 'cost',
              title: $t('Amount'),
              type: 'text',
              sort: true
            },
            {
              column: 'user_name',
              title: $t('Responsible name'),
              type: 'text',
              sort: true
            },
            {
              column: 'status',
              title: $t('Situation'),
              type: 'status',
              sort: true,
              values: [
                { title: $t('Draft'), value: 0, color: 'dark' },
                { title: $t('Approved'), value: 1, color: 'success' }
              ]
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'printSalesDiscount', role: true },
                {
                  name: 'edit',
                  role: $checkRole('sales_discounts_edit')
                },
                { name: 'file' },
                {
                  name: 'delete',
                  role: $checkRole('sales_discounts_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/salesDiscounts'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
