<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="sm-stop-edit" v-if="loading"></div>
        <div class="modal-card card">
          <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
              {{ $t('Import from excel file') }}
            </h4>
            <!-- Close -->
            <button type="button" class="close" @click="close()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-12">
                    {{ $t('Choose an excel file') }}
                  </label>
                  <div class="icon-input col-md-12">
                    <span>
                      <i class="far fa-upload"></i>
                    </span>
                    <div class="search-input">
                      <input
                        type="file"
                        id="file"
                        ref="file"
                        class="form-control"
                        @change="handleFileUpload()"
                        :class="{ 'is-invalid': errors.file }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="close()">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
                <button @click="importFile()" class="btn btn-dark" v-if="!loading">
                  <i class="far fa-save"></i>
                  {{ $t('Create') }}
                </button>
                <button class="btn btn-dark" v-if="loading">
                  {{ $t('Loading') }}
                  <span class="loading-s1 sm-j">.</span><span class="loading-s2 sm-j">.</span
                  ><span class="loading-s3 sm-j">.</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      file: '',
      errors: {
        file: false
      },
      loading: false
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      this.errors.file = false
    },
    importFile() {
      let error = 0
      if (!this.file) {
        error = 1
        this.errors.file = true
      }
      if (error === 0) {
        this.loading = true
        let formData = new FormData()
        formData.append('file', this.file)
        axios
          .post(this.$linkGenerator('/customers/import/excel'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.$parent.$parent.alertText = 'تم استيراد العملاء بنجاح'
            this.$parent.$parent.alertType = 'success'
            this.close()
            this.$parent.getItems()
            this.loading = false
            return response
          })
      }
    },
    close() {
      this.$parent.importShow = false
    }
  }
}
</script>
