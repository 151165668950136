<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Payment Fees')"
          :description="$t('From Here You Can Control The Payment Fees')"
        />
        <indexTable
          :searchText="$t('Search For a Fee')"
          :emptyTableText="$t('There Is No Payment Fee')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="
            $checkRole('sales_payments_add') ||
            $checkRole('purchase_payments_add') ||
            $checkRole('expenses_add')
          "
          :cloumns="[
            {
              column: 'name',
              title: $t('Payment fee name'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: $t('payment Fee Cost'),
              type: 'text',
              sort: true
            },
            {
              column: 'options',
              title: $t('Actions'),
              type: 'options',
              options: [
                {
                  name: 'edit',
                  role:
                    $user.admin ||
                    $checkRole('sales_payments_edit') ||
                    $checkRole('purchase_payments_edit') ||
                    $checkRole('expenses_edit')
                },
                {
                  name: 'delete',
                  role:
                    $user.admin ||
                    $checkRole('sales_payments_delete') ||
                    $checkRole('purchase_payments_delete') ||
                    $checkRole('expenses_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/paymentFees'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
