<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('Reports')" :description="$t('Partners deposit register')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Partner')"
                :plaseholder="$t('Choose partner')"
                v-model="params.partner_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.partner_id"
                :values="$database.partners"
              />
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('user')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('project')"
                :plaseholder="$t('Choose project')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.project_id"
                :value="params.project_id"
                :values="$database.projects"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Safe')"
                :plaseholder="$t('choose safe')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.safe_id"
                :value="params.safe_id"
                :values="$database.safes"
              />

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Partners deposit register')"
            :details="[
              { title: $t('Partner name'), value: supplierName },
              { title: $t('Username'), value: userName },
              { title: $t('Safe'), value: safeName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('There are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'code',
                link: '/partnersDeposits',
                title: $t('Code'),
                type: 'reportMainLink',
                sort: true
              },
              { column: 'date', title: $t('Date'), type: 'text', sort: true },
              { column: 'partner', title: $t('Partner'), type: 'text', sort: true },
              { column: 'user', title: $t('User'), type: 'text', sort: true },
              { column: 'safe', title: $t('safe'), type: 'text', sort: true },
              {
                column: 'cost',
                title: $t('Cost'),
                type: 'text',
                sort: true
              }
            ]"
            :footer="[
              { colspan: 5, value: $t('Total') },
              { colspan: 1, value: cost }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import indexHeader from '@/elements/index/indexHeader.vue'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/partnersPayments/partnersDeposits',
      items: [],
      loading: 1,

      cost: 0,

      params: {
        partner_id: '',
        safe_id: '',
        user_id: '',
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'partners beposits history',
        reportData: '[]'
      },

      users: [],
      partnerName: '',
      userName: '',
      safeName: '',
      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.totalPages = 0
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        this.cost = 0

        this.items.forEach((item) => {
          this.cost += item.cost
          item.cost = this.$moneyFormat(item.cost)

          item.date = this.$dateFormat(item.date)

          var partner = this.$database.partners.find((el) => el.id == item.partner_id)
          item.partner = partner.name

          var user = this.users.find((el) => el.id == item.user_id)
          if (user) {
            item.user = user.name
          }

          var safe = this.$database.safes.find((el) => el.id == item.safe_id)
          if (safe) {
            item.safe = safe.name
          }
        })
        this.cost = this.$moneyFormat(this.cost)
        this.loading = 0
      })

      var partner = this.$database.partners.find((el) => el.id == this.params.partner_id)
      if (partner) {
        this.partnerName = partner.name
      } else {
        this.partnerName = ''
      }

      var safe = this.$database.safes.find((el) => el.id == this.params.safe_id)
      if (safe) {
        this.safeName = safe.name
      } else {
        this.safeName = ''
      }

      var user = this.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.$updateDatabase(['safes', 'customers', 'users'])
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
