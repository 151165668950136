var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('quotations.quotations'),"description":_vm.$t('quotations.fromHereYouCanControlTheOffers')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('quotations.searchForAnQuotation'),"emptyTableText":_vm.$t('quotations.thereAreNoOffers'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('quotations_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('quotations.situation'), value: '' },
          { title: _vm.$t('quotations.all'), value: '' },
          { title: _vm.$t('quotations.draft'), value: 0 },
          { title: _vm.$t('quotations.sent'), value: 1 },
          { title: _vm.$t('quotations.seen'), value: 2 },
          { title: _vm.$t('quotations.approved'), value: 3 },
          { title: _vm.$t('quotations.rejected'), value: 4 },
          { title: _vm.$t('quotations.canceled'), value: 5 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('quotations.quotation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('quotations.quotationdate'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('quotations.customerName'),
            type: 'clintLink',
            sort: false
          },
          {
            column: 'total',
            title: _vm.$t('quotations.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('quotations.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('quotations.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('quotations.sent'), value: 1, color: 'primary' },
              { title: _vm.$t('quotations.seen'), value: 2, color: 'primary' },
              {
                title: _vm.$t('quotations.approved'),
                value: 3,
                color: 'success'
              },
              { title: _vm.$t('quotations.rejected'), value: 4, color: 'danger' },
              { title: _vm.$t('quotations.canceled'), value: 5, color: 'danger' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('quotations.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('quotations_edit')
              },
              { name: 'file' },
              { name: 'outLink', role: true },
              { name: 'printQuotation', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('quotations_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }