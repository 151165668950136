<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('Reports')" :description="$t('Sales by product')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose customer')"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />

              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('User')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Project')"
                :plaseholder="$t('Choose project')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.project_id"
                :value="params.project_id"
                :values="$database.projects"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Store')"
                :plaseholder="$t('Choose store')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Point of sale')"
                :plaseholder="$t('Choose point of sale')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Session number') }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('Enter session number')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Order type') }}</label>
                  <select type="text" v-model="params.table_number" class="form-control">
                    <option value="">{{ $t('Choose order type') }}</option>
                    <option value="0">{{ $t('Take away') }}</option>
                    <option value="1">{{ $t('Indoor') }}</option>
                  </select>
                </div>
              </div>
              <searchInput
                class="col-md-3"
                :title="$t('Shipping agent')"
                :plaseholder="$t('Choose shipping agent')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.delivery_id"
                :value="params.delivery_id"
                :values="$database.deliveries"
              />

              <searchInput
                class="col-md-3"
                :title="$t('Marketer')"
                :plaseholder="$t('Choose marketer')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.marketer_id"
                :value="params.marketer_id"
                :values="$database.marketers"
              />

              <searchInput
                class="col-md-3"
                :title="$t('Product')"
                :plaseholder="$t('Choose product')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.product_id"
                :value="params.product_id"
                :values="$database.products"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Parent category')"
                :plaseholder="$t('Choose category')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.section_parent_id"
                :value="params.section_parent_id"
                :values="$database.sections"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Products Category')"
                :plaseholder="$t('Choose Category')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.section_id"
                :value="params.section_id"
                :values="$database.sections"
              />
              <!-- <div class="form-group col-md-3">
                <label> تصنيف المنتجات </label>
                <multiselect
                  v-model="selectedSections"
                  :options="$database.sections"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :plaseholder="$t('Choose category')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div> -->
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Sales by product')"
            :details="[
              { title: 'اسم العميل', value: customerName },
              { title: 'اسم المستخدم', value: userName },
              { title: 'المخزن', value: storeName },
              { title: 'نقطة البيع', value: pointOfSaleName },
              { title: 'رقم الجلسة', value: sessionNumber },
              { title: 'نوع الطلب', value: tableNumber },
              { title: 'مندوب الشحن', value: deliveryName },
              { title: 'المسوق', value: marketerName },
              { title: 'المنتج', value: productName },
              { title: 'التصنيف', value: sectionName },
              { title: 'التصنيف الأب', value: sectionParentName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('There are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'product_name',
                link: '/products',
                title: $t('Product name'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'quantity',
                title: $t('Quantity'),
                type: 'quantity',
                sort: true
              },
              {
                column: 'average',
                title: $t('Average'),
                type: 'text',
                sort: true,
                supData: true
              },
              {
                column: 'price',
                title: $t('Total'),
                type: 'text',
                sort: true
              }
              // {
              //   column: 'earning',
              //   title: 'الربح',
              //   type: 'text',
              //   sort: true,
              //   disable: !(this.$user.admin || this.$user.role.reports_earning_show),
              //   supData: true
              // }
            ]"
            :footer="[
              { colspan: 1, value: 'المجموع' },
              { colspan: 1, value: quantity },
              { colspan: 1, value: '', supData: true },
              { colspan: 1, value: price }
              // {
              //   colspan: 1,
              //   value: earning,
              //   disable: !(this.$user.admin || this.$user.role.reports_earning_show)
              // }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportTable from '@/elements/reports/reportTable.vue'
import indexHeader from '@/elements/index/indexHeader.vue'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
import reportHeader from '@/elements/reports/reportHeader.vue'

export default {
  data() {
    return {
      path: '/reports/sales/products',
      items: [],

      loading: 0,

      quantity: 0,
      price: 0,
      average: 0,
      earning: 0,

      params: {
        customer_id: '',
        store_id: '',
        user_id: '',
        point_of_sale_id: '',
        session_number: '',
        delivery_id: '',
        table_number: '',
        section_parent_id: '',
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'sales by item',
        reportData: '[]'
      },

      users: [],
      storeName: '',
      customerName: '',
      userName: '',
      pointOfSaleName: '',
      sessionNumber: '',
      tableNumber: null,
      deliveryName: '',
      marketerName: '',
      productName: '',
      sectionName: '',
      sectionParentName: '',

      searchStartDate: '',
      searchEndDate: '',

      selectedSections: []
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        this.quantity = 0
        this.price = 0
        this.average = 0
        this.earning = 0

        this.items = []
        response.data.forEach((item) => {
          var mitem = this.items.find(
            (el) => el.product_id == item.product_id && el.detail_id == item.detail_id
          )
          if (typeof mitem === 'undefined') {
            item.price = item.price * item.quantity
            this.items.push(item)
            var mitem = this.items.find(
              (el) => el.product_id == item.product_id && el.detail_id == item.detail_id
            )
          } else {
            mitem.quantity += item.quantity
            mitem.earning += item.earning
            mitem.price += item.price * item.quantity
          }
          mitem.average = mitem.price != 0 ? Math.ceil(mitem.price / mitem.quantity) : 0
          this.average += mitem.average
          mitem.average = this.$moneyFormat(mitem.average)
        })
        this.items.forEach((item) => {
          item.id = item.product_id
          this.quantity += item.quantity
          this.price += item.price
          this.earning += item.earning
          item.price = this.$moneyFormat(item.price)
          item.earning = this.$moneyFormat(item.earning)

          var detail = this.$database.details.find((el) => el.id == item.detail_id)
          if (detail) {
            item.detail = detail.name
          }
        })
        this.average = this.$moneyFormat(this.average)
        this.earning = this.$moneyFormat(this.earning)
        this.price = this.$moneyFormat(this.price)
        this.loading = 0
      })

      var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
      if (customer) {
        this.customerName = customer.name
      } else {
        this.customerName = ''
      }

      var store = this.$database.stores.find((el) => el.id == this.params.store_id)
      if (store) {
        this.storeName = store.name
      } else {
        this.storeName = ''
      }

      var user = this.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      this.sessionNumber = this.params.session_number

      var delivery1 = this.$database.deliveries.find((el) => el.id == this.params.delivery_id)
      if (delivery1) {
        this.deliveryName = delivery1.name
      } else {
        this.deliveryName = ''
      }

      var marketer = this.$database.marketers.find((el) => el.id == this.params.marketer_id)
      if (marketer) {
        this.marketerName = marketer.name
      } else {
        this.marketerName = ''
      }

      var product = this.$database.products.find((el) => el.id == this.params.product_id)
      if (product) {
        this.productName = product.name
      } else {
        this.productName = ''
      }

      var section = this.$database.sections.find((el) => el.id == this.params.section_parent_id)
      if (section) {
        this.sectionParentName = section.name
      } else {
        this.sectionParentName = ''
      }

      var sectionsName = []

      var sections = this.params.section_id ? this.params.section_id.split(',') : []
      sections.forEach((el) => {
        var section = this.$database.sections.find((item) => item.id == el)
        if (section) {
          sectionsName.push(section.name)
        }
      })

      this.sectionName = sectionsName.toString(' , ')

      if (this.params.table_number == 1) {
        this.tableNumber = 'الصالة'
      } else if (this.params.table_number === '0') {
        this.tableNumber = 'تيك اواي'
      } else {
        this.tableNumber = ''
      }

      var pointOfSale = this.$database.pointOfSales.find(
        (el) => el.id == this.params.point_of_sale_id
      )
      if (pointOfSale) {
        this.pointOfSaleName = pointOfSale.name
      } else {
        this.pointOfSaleName = ''
      }
    },
    getItems() {
      this.$setParams(this.params)
      if (this.params.orderBy) {
        this.items.sort(this.$dynamicSort(this.params))
      }
    }
  },
  mounted() {
    this.params = this.$getParams()
    var sections = this.params.section_id ? this.params.section_id.split(',') : []
    sections.forEach((el) => {
      var item = this.$database.sections.find((item) => item.id == el)
      this.selectedSections.push(item)
    })
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
    this.$updateDatabase(['users'])
  },
  watch: {
    selectedSections: function (val) {
      this.params.section_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  components: {
    reportTable,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
    reportHeader
  }
}
</script>
