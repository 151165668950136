<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Expense Sections')"
          :description="$t('from Here Control Over Expense Sections')"
        />
        <indexTable
          :searchText="$t('Search For A section')"
          :emptyTableText="$t('there Are No Sections')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('expenses_add')"
          :cloumns="[
            {
              column: 'name',
              title: $t('Section name'),
              type: 'text',
              sort: true
            },
            {
              column: 'expense_section_id',
              title: $t('Father section'),
              type: 'link',
              to: 'expenseSection',
              sort: true,
              link: false
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'edit', role: $checkRole('expenses_edit') },
                {
                  name: 'delete',
                  role: $checkRole('expenses_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/expenseSections'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
