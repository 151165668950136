var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('ReceivingPoints'),"description":_vm.$t('from Here You Can Control Your Receipt Points'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('SearchForAReceivingPoint'),"emptyTableText":_vm.$t('ThereAreNoReceivingPoints'),"buttonRole":_vm.$checkRole('pickupLocations_add'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('the Name Of The Pickup Locations'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'mobile',
            title: _vm.$t('mobile'),
            type: 'text',
            sort: true
          },
          {
            column: 'city',
            title: _vm.$t('City'),
            type: 'text',
            sort: true
          },
          {
            column: 'country',
            title: _vm.$t('Country'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('pickupLocations_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('pickupLocations_transfers_add'),
                title: _vm.$t('MoveFromTheStore'),
                icon: 'suitcase',
                link: '/pickupLocationsTransfers/create/'
              },
              {
                name: 'link',
                role: _vm.$checkRole('pickupLocations_deposits_add'),
                title: _vm.$t('DepositToTheStore'),
                icon: 'donate',
                link: '/pickupLocationsDeposits/create/'
              },
              {
                name: 'link',
                role: _vm.$checkRole('pickupLocations_withdrawals_add'),
                title: _vm.$t('EnglishPullOutOfTheStore'),
                icon: 'money-bill-alt',
                link: '/pickupLocationsWithdrawals/create/'
              },
              {
                name: 'delete',
                role: _vm.$checkRole('pickupLocations_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }