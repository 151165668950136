var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[(_vm.$route.name == 'safesDepositsCreate')?_c('addHeader',{attrs:{"title":_vm.$t('Add safes Deposit'),"description":_vm.$t('From here you can create a new safes Deposit')}}):_vm._e(),(_vm.$route.name == 'safesDepositsEdit')?_c('addHeader',{attrs:{"title":_vm.$t('Sales payment adjustment'),"description":_vm.$t('From here you can modify the safes Deposit information')}}):_vm._e(),_c('div',{staticClass:"mb-4 row"},[(_vm.$route.name == 'safesDepositsCreate')?_c('connectionInput',{attrs:{"title":_vm.$t('Numbering the process'),"group":_vm.$t('Groups'),"disabled":_vm.$checkRole('sales_payments_edit_item') ? false : true,"values":_vm.$database.invoiceGroups,"hasErorr":_vm.errors.invoice_group,"error":_vm.$t('Numbering is required')},model:{value:(_vm.item.invoice_group),callback:function ($$v) {_vm.$set(_vm.item, "invoice_group", $$v)},expression:"item.invoice_group"}}):_vm._e(),(_vm.$route.name == 'safesDepositsEdit')?_c('formInput',{attrs:{"title":_vm.$t('Operation code'),"disabled":true,"maxlength":"255"},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}}):_vm._e(),_c('subFileInput',{attrs:{"title":_vm.$t('Invoice file'),"file":_vm.item.file,"path":'invoices',"hasErorr":_vm.errors.file,"error":_vm.$t('Choose a suitable file')},model:{value:(_vm.item.file),callback:function ($$v) {_vm.$set(_vm.item, "file", $$v)},expression:"item.file"}}),_c('formInput',{attrs:{"title":_vm.$t('Reference'),"maxlength":"255"},model:{value:(_vm.item.refrance),callback:function ($$v) {_vm.$set(_vm.item, "refrance", $$v)},expression:"item.refrance"}}),_c('searchGroupInput',{attrs:{"type":'safe',"values":_vm.$database.safes,"inputs":[
            { title: _vm.$t('bills.form.Treasury'), show: 'name' },
            { title: _vm.$t('Balance'), show: 'balance', moany: true }
          ]}}),_c('div',{staticClass:"form-group row",class:'col-md-6'},[_c('label',{class:'col-md-4'},[_vm._v(_vm._s(_vm.$t('Payment date')))]),_c('div',{class:'col-md-7'},[_c('div',{staticClass:"icon-input w-100 to-left"},[_vm._m(0),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('Choose time and date'),"button-now-translation":_vm.$t('Now'),"disabled":_vm.$checkRole('sales_payments_edit_item') ? false : true,"format":"YYYY-MM-DD hh:mm a","color":"#631263","button-color":"#631263"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)])]),_c('selectInput',{attrs:{"title":_vm.$t('Situation'),"values":[
            { name: _vm.$t('Draft'), value: 0 },
            { name: _vm.$t('Approved'), value: 1 }
          ]},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}}),(_vm.$route.name == 'safesDepositsCreate')?_c('checkInput',{attrs:{"title":_vm.$t('Print the process'),"value":_vm.print},model:{value:(_vm.print),callback:function ($$v) {_vm.print=$$v},expression:"print"}}):_vm._e(),_c('dvider',{attrs:{"title":_vm.$t('Accounts information'),"description":_vm.$t('Account information details')}}),_c('monyInput',{attrs:{"title":_vm.$t('Received amount'),"hasErorr":_vm.errors.cost,"error":_vm.$t('The amount received must be greater than')},model:{value:(_vm.item.cost),callback:function ($$v) {_vm.$set(_vm.item, "cost", $$v)},expression:"item.cost"}}),_c('searchGroupInput',{attrs:{"type":'paymentMethod',"values":_vm.$database.paymentMethods,"inputs":[{ show: 'name' }]}}),_c('dvider',{attrs:{"title":_vm.$t('Payment notes'),"description":_vm.$t('Payment notes details')}}),_c('formTextarea',{attrs:{"title":_vm.$t('That about')},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('formTextarea',{attrs:{"title":_vm.$t('Note')},model:{value:(_vm.item.notes),callback:function ($$v) {_vm.$set(_vm.item, "notes", $$v)},expression:"item.notes"}}),_c('hr',{staticClass:"mt-5 mb-5 col-12"}),(_vm.$route.name == 'safesDepositsCreate' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":function($event){return _vm.saveItem()}}},[_vm._v(" "+_vm._s(_vm.$t('Add safes Deposit'))+" ")]):_vm._e(),(_vm.$route.name == 'safesDepositsEdit' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":function($event){return _vm.saveItem()}}},[_vm._v(" "+_vm._s(_vm.$t('Edit safes deposits'))+" ")]):_vm._e(),(_vm.$parent.stopEdit)?_c('loading'):_vm._e()],1)],1)]),(_vm.model == 'addPaymentMethod')?_c('addPaymentMethod'):_vm._e(),(_vm.model == 'addPaymentFee')?_c('addPaymentFee'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"far fa-calendar"})])
}]

export { render, staticRenderFns }