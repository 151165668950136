var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('pointOfSales'),"description":_vm.$t('pointOfSalesDescription')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('FindAPointOfSale'),"emptyTableText":_vm.$t('thereAreNopointOfSales'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('point_of_sales_add'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('name'),
            type: 'text',
            sort: true
          },
          {
            column: 'store_id',
            title: _vm.$t('store'),
            type: 'link',
            to: 'store',
            sort: true,
            link: false
          },
          {
            column: 'safe_id',
            title: _vm.$t('safe'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: false
          },
          {
            column: 'balance',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true,
            disable: !_vm.$checkRole('point_of_sales_show_balance') ? 1 : 0
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'stat',
            sort: true
          },
          {
            column: 'password',
            title: _vm.$t('DeviceCode'),
            type: 'text',
            sort: true
          },
          {
            column: 'session_number',
            title: _vm.$t('SessionNo'),
            type: 'text',
            sort: true
          },

          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'pointOfSale' },
              {
                name: 'pointOfSaleReport',
                role: _vm.$checkRole('reports_show'),
                title: 'تقرير المبيعات',
                icon: 'chart-pie',
                link: '/salesByItem?session_number= ' + +'&point_of_sale_id='
              },
              {
                name: 'newSession',
                role: _vm.$checkRole('point_of_sales_edit')
              },
              {
                name: 'posLink',
                role: _vm.$checkRole('safes_deposits_add'),
                title: _vm.$t('safes.DepositToTheSafe'),
                icon: 'donate',
                link: '/safesDeposits/create'
              },
              {
                name: 'posLink',
                role: _vm.$checkRole('safes_withdrawals_add'),
                title: _vm.$t('safes.WithdrawalFromTheTreasury'),
                icon: 'money-bill-alt',
                link: '/safesWithdrawals/create'
              },
              {
                name: 'posLinkTransefere',
                role: _vm.$checkRole('safes_transfers_add'),
                title: _vm.$t('safes.TransferFromTheTreasury'),
                icon: 'suitcase',
                link: '/safesTransfers/create'
              },
              {
                name: 'edit',
                role: _vm.$checkRole('point_of_sales_edit')
              },
              {
                name: 'delete',
                role: _vm.$checkRole('point_of_sales_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)]),(_vm.changePointOfSale)?_c('changeSession'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }