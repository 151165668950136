var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"ml-auto"},[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('pointOfSales.invoices.pointOfSalesInvoices'))+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('pointOfSales.invoices.fromHereYouCanControlPOSInvoices'))+" ")])]),_c('router-link',{staticClass:"btn btn-primary add-btn",attrs:{"to":'/pointofsales/' + this.$route.params.id}},[_c('i',{staticClass:"fas fa-cash-register mr-2"}),_vm._v(" "+_vm._s(_vm.$t('pointOfSales.invoices.PointOfSaleOffer'))+" ")])],1),_c('indexTable',{attrs:{"searchText":_vm.$t('invoices.searchForAnInvoice'),"statusSearch":true,"statusModel":'order_type',"statusValues":[
          { title: _vm.$t('pointOfSales.invoices.type'), value: '' },
          { title: _vm.$t('pointOfSales.invoices.Receipt'), value: 0 },
          { title: _vm.$t('pointOfSales.invoices.connecting'), value: 1 },
          { title: _vm.$t('pointOfSales.invoices.hall'), value: 2 }
        ],"emptyTableText":_vm.$t('pointOfSales.invoices.thereAreNoInvoices'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"filter":{ point_of_sale_id: _vm.$route.params.id },"withoutAdd":true,"localDatabaseTable":"pointOfSalesInvoices","table":"pointOfSalesInvoices","deleteRoute":"/invoices","cloumns":[
          {
            column: 'order_number',
            title: _vm.$t('pointOfSales.invoices.BillNumber'),
            type: 'text',
            sort: true,
            link: false
          },
          {
            column: 'date',
            title: _vm.$t('pointOfSales.invoices.theDateOfTheInvoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'customer_id',
            title: _vm.$t('invoices.customerName'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: false
          },
          {
            column: 'order_type',
            title: _vm.$t('pointOfSales.invoices.type'),
            type: 'status',
            sort: true,
            values: [
              {
                title: _vm.$t('pointOfSales.invoices.Receipt'),
                value: 0,
                color: 'success'
              },
              {
                title: _vm.$t('pointOfSales.invoices.connecting'),
                value: 1,
                color: 'dark'
              },
              {
                title: _vm.$t('pointOfSales.invoices.hall'),
                value: 2,
                color: 'primary'
              }
            ]
          },
          {
            column: 'total',
            title: _vm.$t('invoices.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('invoices.settings'),
            type: 'options',
            options: [
              { name: 'showPointOfSale' },
              { name: 'edit', role: _vm.$checkRole('invoices_edit') },
              {
                name: 'invoiceLink',
                role: _vm.$checkRole('sale_returns_add'),
                title: _vm.$t('pointOfSales.invoices.ReturnSale'),
                icon: 'exchange',
                link: '/saleReturns/create?invoice_id='
              },
              { name: 'printPointOfSale', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('invoices_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)]),(_vm.show)?_c('invoiceShow'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }