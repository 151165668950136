var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Suppliers'),"description":_vm.$t('From here you can control suppliers'),"showExport":'true',"exportLink":_vm.path + '/export/excel',"showImport":'true',"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for supplier'),"emptyTableText":_vm.$t('There are no suppliers'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('suppliers_add'),"localData":true,"cloumns":[
          { column: 'id', title: 'كود', type: 'mainLink', sort: true },
          {
            column: 'name',
            title: _vm.$t('Supplier name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'mobile',
            title: _vm.$t('Phone number'),
            type: 'text',
            sort: true,
            icon: 'phone'
          },
          {
            column: 'balance',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('Actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('suppliers_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('purchase_payments_add'),

                title: _vm.$t('Add a batch'),
                icon: 'dollar-sign',
                link: '/purchasePayments/create?supplier_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('bills_add'),

                title: _vm.$t('Add a purchase bill'),
                icon: 'file-invoice-dollar',
                link: '/bills/create?supplier_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('purchase_orders_add'),
                title: _vm.$t('Add a purchase order'),
                icon: 'briefcase',
                link: '/purchaseorders/create?supplier_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('suppliers_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t(
            'Are you really sure you deleted the resource if you delete the resource all invoices and processes will Be deleted'
          ),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }