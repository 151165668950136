var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('Partners deposits')))]),_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/partnersDeposits/create?partner_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('Addition'))+" ")])],1),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for an operation'),"emptyTableText":_vm.$t('No deposits'),"filter":{ partner_id: _vm.$route.params.id },"withoutCard":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('Process'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'partner_id',
          title: _vm.$t('Partner name'),
          type: 'link',
          to: 'partner',
          sort: true,
          link: true
        },
        {
          column: 'safe_id',
          title: _vm.$t('Treasury'),
          type: 'link',
          to: 'safe',
          sort: true,
          link: true
        },
        {
          column: 'date',
          title: _vm.$t('date'),
          type: 'text',
          sort: true
        },
        {
          column: 'description',
          title: _vm.$t('And that is about'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$t('cost'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$t('Actions'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('partners_deposits_edit')
            },
            { name: 'printSalesPayment', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role: _vm.$checkRole('partners_deposits_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
        close: _vm.$t('Close'),
        confirm: _vm.$t('Confirm'),
        loading: _vm.$t('Loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }