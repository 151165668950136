var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Daily restrictions details')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Report options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Daily restrictions details'),"details":[]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some and try again'),"cloumns":[
            {
              column: 'code',
              title: _vm.$t('code'),
              type: 'reportCode'
            },
            { column: 'type', title: _vm.$t('Process'), type: 'reportType' },
            {
              column: 'date',
              title: _vm.$t('Date'),
              type: 'text'
            },
            {
              column: 'debit',
              title: _vm.$t('Debtor'),
              type: 'textOrEmpty'
            },
            {
              column: 'creditor',
              title: _vm.$t('Creditor'),
              type: 'textOrEmpty'
            },
            {
              column: 'statement',
              title: _vm.$t('Statement'),
              type: 'statement'
            },
            {
              column: 'account',
              title: _vm.$t('Account name'),
              type: 'account'
            }
          ],"footer":[
            { colspan: 3, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            { colspan: 1, value: '' },
            { colspan: 1, value: _vm.total }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }