var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Companies'),"description":_vm.$t('From Here You Can Control Your Own Companies')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search For A Company'),"emptyTableText":_vm.$t('There Are NoCompanies'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('companies_add'),"localData":true,"cloumns":[
          {
            column: 'name',
            title: _vm.$t('companyName'),
            type: 'text',
            sort: true
          },
          {
            column: 'address1',
            title: _vm.$t('company address'),
            type: 'text',
            sort: true
          },
          {
            column: 'city',
            title: _vm.$t('City of the company'),
            type: 'text',
            sort: true
          },
          {
            column: 'country',
            title: _vm.$t('country of the company'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              {
                name: 'edit',
                role: _vm.$checkRole('companies_edit')
              },
              {
                name: 'delete',
                role: _vm.$checkRole('companies_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }