var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Safes'),"description":_vm.$t('Safes description'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for a safe'),"emptyTableText":_vm.$t('There are no safes'),"buttonRole":_vm.$checkRole('safes_add'),"localData":true,"filter":{ bank_account: 0 },"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          { column: 'id', title: _vm.$t('Code'), type: 'mainLink', sort: true },
          {
            column: 'name',
            title: _vm.$t('Customer name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'balance',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('Actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('safes_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_transfers_add'),

                title: _vm.$t('Transfer from the treasury'),
                icon: 'suitcase',
                link: '/safesTransfers/create?from_safe_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_deposits_add'),
                title: _vm.$t('Deposit to the safe'),
                icon: 'donate',
                link: '/safesDeposits/create?safe_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_withdrawals_add'),
                title: _vm.$t('Withdrawal from the treasury'),
                icon: 'money-bill-alt',
                link: '/safesWithdrawals/create?safe_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('safes_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }