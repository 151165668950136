var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Reports'),"description":'طلبات الشراء حسب المنتج.'}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Supplier'),"plaseholder":_vm.$t('Choose supplier'),"show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.$database.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('user'),"plaseholder":_vm.$t('Choose user'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Store'),"plaseholder":_vm.$t('Choose store'),"show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"طلبات الشراء حسب المنتج","details":[
            { title: 'اسم المورد', value: _vm.supplierName },
            { title: _vm.$t('Username'), value: _vm.userName },
            { title: _vm.$t('Store'), value: _vm.storeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'product_name',
              link: '/products',
              title: _vm.$t('Product name'),
              type: 'reportMainLink',
              sort: true
            },

            {
              column: 'quantity',
              title: 'الكمية المباعة',
              type: 'text',
              sort: true
            },
            {
              column: 'average',
              title: 'متوسط السعر',
              type: 'text',
              sort: true
            },
            {
              column: 'price',
              title: 'المبلغ',
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 1, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.quantity },
            { colspan: 1, value: _vm.average },
            { colspan: 1, value: _vm.price }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }