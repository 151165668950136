<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('invoices')"
          :description="$t('From here you can control sales invoices')"
        />
        <indexTable
          :searchText="$t('search for an invoice')"
          :emptyTableText="$t('There are no bills')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('invoices_add')"
          :statusSearch="true"
          :statusValues="[
            {
              title: $t('Situation'),
              value: ''
            },
            {
              title: $t('All'),
              value: ''
            },
            {
              title: $t('Draft'),
              value: 0
            },
            {
              title: $t('Sent'),
              value: 1
            },
            {
              title: $t('Seen'),
              value: 2
            },
            {
              title: $t('Driven'),
              value: 3
            },
            {
              title: $t('Canceled'),
              value: 4
            }
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('Invoice'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'date',
              title: $t('The date of the invoice'),
              type: 'text',
              sort: true
            },
            {
              column: 'client_type',
              title: $t('Customer name'),
              type: 'clintLink',
              sort: false
            },
            {
              column: 'total',
              title: $t('Cost'),
              type: 'text',
              sort: true
            },
            {
              column: 'rest',
              title: $t('Balance'),
              type: 'text',
              sort: true
            },
            {
              column: 'user_name',
              title: $t('Responsible name'),
              type: 'text',
              sort: true
            },
            {
              column: 'status',
              title: $t('Situation'),
              type: 'status',
              sort: true,
              values: [
                {
                  title: $t('Draft'),
                  value: 0,
                  color: 'dark'
                },
                {
                  title: $t('Sent'),
                  value: 1,
                  color: 'primary'
                },
                {
                  title: $t('Seen'),
                  value: 2,
                  color: 'primary'
                },
                {
                  title: $t('Driven'),
                  value: 3,
                  color: 'success'
                },
                {
                  title: $t('Canceled'),
                  value: 4,
                  color: 'danger'
                }
              ]
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                {
                  name: 'show'
                },
                {
                  name: 'edit',
                  role: $checkRole('invoices_edit') && !$option.e_invoicing
                },
                {
                  name: 'file'
                },
                {
                  name: 'invoiceLink',
                  role: $checkRole('sale_returns_add'),
                  title: $t('ReturnSale'),
                  icon: 'exchange',
                  link: '/saleReturns/create?invoice_id='
                },
                {
                  name: 'outLink',
                  role: true
                },
                {
                  name: 'printInvoice',
                  role: true
                },
                {
                  name: 'download',
                  role: true
                },
                {
                  name: 'delete',
                  role: $checkRole('invoices_delete') && !$option.e_invoicing
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/invoices'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
