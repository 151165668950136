<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('reports')" :description="$t('customer Guide')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('report Options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('customer Name') }}</label>
                  <input
                    type="text"
                    v-model="params.name"
                    class="form-control"
                    :placeholder="$t('enter Customer Name')"
                  />
                </div>
              </div>
              <searchInput
                class="col-md-3"
                :title="$t('section Customer')"
                :plaseholder="$t('choose Section')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.sections_account_id"
                :value="params.sections_account_id"
                :values="$database.sectionsAccounts"
              />
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('City') }}</label>
                  <input
                    type="text"
                    v-model="params.city"
                    class="form-control"
                    :placeholder="$t('enter City')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('state') }}</label>
                  <input
                    type="text"
                    v-model="params.country"
                    class="form-control"
                    :placeholder="$t('enter State')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('address') }}</label>
                  <input
                    type="text"
                    v-model="params.address1"
                    class="form-control"
                    :placeholder="$t('enter Address')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('complement') }}</label>
                  <input
                    type="text"
                    v-model="params.address2"
                    class="form-control"
                    :placeholder="$t('enter Complement')"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Phone number') }}</label>
                  <input
                    type="text"
                    v-model="params.mobile"
                    class="form-control"
                    :placeholder="$t('enter Phone Number')"
                  />
                </div>
              </div>
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('customer Guide')"
            :details="[
              {
                title: $t('Customer name'),
                value: customerName
              },
              { title: $t('City'), value: cityName },
              { title: $t('state'), value: countryName },
              { title: $t('address'), value: address1Name },
              { title: $t('complement'), value: address2Name },
              { title: $t('Phone number'), value: mobileNum },
              {
                title: $t('section Customer'),
                value: sectionCustomerName
              }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there No Data The Moment')"
            :emptyTableSubText="$t('try Adding Some And Try Again')"
            :cloumns="[
              {
                column: 'name',
                link: '/customers',
                title: $t('customer Name'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'tax_number',
                title: $t('tax Identification Number'),
                type: 'text',
                sort: true
              },
              {
                column: 'city',
                title: $t('City'),
                type: 'text',
                sort: true
              },
              {
                column: 'country',
                title: $t('state'),
                type: 'text',
                sort: true
              },
              {
                column: 'address1',
                title: $t('address'),
                type: 'text',
                sort: true
              },
              {
                column: 'address2',
                title: $t('complement'),
                type: 'text',
                sort: true
              },
              {
                column: 'mobile',
                title: $t('Phone number'),
                type: 'text',
                sort: true
              }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import indexHeader from '@/elements/index/indexHeader.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/customers/customersGuide',
      items: [],
      loading: 0,
      customers: [],
      sectionCustomerName: '',
      params: {
        name: '',
        city: '',
        country: '',
        address1: '',
        address2: '',
        sections_account_id: ''
      },
      excel: {
        fileName: 'customers guide',
        reportData: '[]'
      },
      customerName: '',
      cityName: '',
      countryName: '',
      address1Name: '',
      address2Name: '',
      mobileNum: ''
    }
  },
  methods: {
    getItems() {
      this.$setParams(this.params)
      this.getData()
    },
    getData() {
      this.$setParams(this.params)
      var items = JSON.parse(JSON.stringify(this.$database.customers))

      items = items.filter((el) => el.stat == 1)

      if (this.params.orderBy) {
        items = items.sort(this.$dynamicSort(params))
      }

      var editName = new RegExp(this.params.name, 'i')
      items = items.filter((el) => editName.test(el.name))

      var editCity = new RegExp(this.params.city, 'i')
      items = items.filter((el) => editCity.test(el.city))

      var editCountry = new RegExp(this.params.country, 'i')
      items = items.filter((el) => editCountry.test(el.country))

      var editAddress1 = new RegExp(this.params.address1, 'i')
      items = items.filter((el) => editAddress1.test(el.address1))

      var editAddress2 = new RegExp(this.params.address2, 'i')
      items = items.filter((el) => editAddress2.test(el.address2))

      var editMobile = new RegExp(this.params.mobile, 'i')
      items = items.filter((el) => editMobile.test(el.mobile))

      if (this.params.sections_account_id) {
        items = items.filter((el) => el.sections_account_id == this.params.sections_account_id)
      }

      var sectionCustomer = this.$database.sectionsAccounts.find(
        (el) => el.id == this.params.sections_account_id
      )
      if (sectionCustomer) {
        this.sectionCustomerName = sectionCustomer.name
      } else {
        this.sectionCustomerName = ''
      }

      this.items = items

      this.customerName = this.params.name
      this.cityName = this.params.city
      this.countryName = this.params.country
      this.address1Name = this.params.address1
      this.address2Name = this.params.address2
      this.mobileNum = this.params.mobile
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    this.customers = this.$database.customers.filter((el) => el.stat == 1)
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    indexHeader,
    searchInput,
    actionButtons
  }
}
</script>
