var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Safes'),"description":_vm.$t('From here you can control your safes reports')}})],1),(
      (_vm.$user.role.available_reports.find((el) => el.id == 9 || el.id == 10) || _vm.$user.admin) &&
      _vm.$site.safes_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('safes'),"icon":'fa-treasure-chest',"reports":[
      {
        name: _vm.$t('Treasury statement'),
        link: './safesAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 9) || _vm.$user.admin
      },
      {
        name: _vm.$t('Safe Balances'),
        link: '/safersBalance',
        role: _vm.$user.role.available_reports.find((el) => el.id == 10) || _vm.$user.admin
      },
      {
        name: _vm.$t('safes Move'),
        link: '/safesMove',
        role: _vm.$user.role.available_reports.find((el) => el.id == 10) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 25 || el.id == 26) || _vm.$user.admin) &&
      (_vm.$site.salesPayments_allow || _vm.$site.purchasePayments_allow) &&
      (_vm.$user.admin || _vm.$user.role.sales_payments_show || _vm.$user.role.purchase_payments_show)
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Payments'),"icon":'fa-credit-card-front',"reports":[
      {
        name: _vm.$t('Sales Payments Register'),
        link: './paymentHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 25) || _vm.$user.admin
      },
      {
        name: _vm.$t('Purchase Payments Register'),
        link: '/purchasePaymentHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 26) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || _vm.$user.admin) &&
      _vm.$site.expenses_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Expenses'),"icon":'fa-usd-square',"reports":[
      {
        name: _vm.$t('Expense List'),
        link: './expenseHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 31) || _vm.$user.admin
      },
      {
        name: _vm.$t('Expenses By Category'),
        link: '/expenseByCategory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 32) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || _vm.$user.admin) &&
      _vm.$site.expenses_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Incomes'),"icon":'fa-usd-square',"reports":[
      {
        name: _vm.$t('Incomes register'),
        link: './incomeHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 31) || _vm.$user.admin
      },
      {
        name: _vm.$t('Incomes By Category'),
        link: '/incomeByCategory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 32) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 13 || el.id == 14) || _vm.$user.admin) &&
      (_vm.$site.safesDeposits_allow || _vm.$site.safesWithdrawals_allow || _vm.$site.safesTransfers_allow) &&
      //Review check multi roles *
      (_vm.$user.admin ||
        _vm.$user.role.safes_deposits_show ||
        _vm.$user.role.safes_withdrawals_show ||
        _vm.$user.role.safes_transfers_show)
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Safes operations'),"icon":'fa-file-invoice-dollar',"reports":[
      {
        name: _vm.$t('Safes Deposits register'),
        link: './safeDepositHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 13) || _vm.$user.admin
      },
      {
        name: _vm.$t('Safes withdrawals register'),
        link: '/safeWithdrawalHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
      },
      {
        name: _vm.$t('Safes transfers register'),
        link: '/safeTransferHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
      }
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }