var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('payment Methods'),"description":_vm.$t('from Here You Can Control The Payment Methods')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search For A Payment Method'),"emptyTableText":_vm.$t('there Are No Payment Methods'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('sales_payments_add') ||
          _vm.$checkRole('purchase_payments_add') ||
          _vm.$checkRole('expenses_add')
            ? 1
            : 0,"cloumns":[
          {
            column: 'name',
            title: _vm.$t('Payment method'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('Actions'),
            type: 'options',
            options: [
              {
                name: 'edit',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('sales_payments_edit') ||
                  _vm.$checkRole('purchase_payments_edit') ||
                  _vm.$checkRole('expenses_edit')
              },
              {
                name: 'delete',
                role:
                  _vm.$user.admin ||
                  _vm.$checkRole('sales_payments_delete') ||
                  _vm.$checkRole('purchase_payments_delete') ||
                  _vm.$checkRole('expenses_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }