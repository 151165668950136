<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('Transfers from warehouse') }}
        </h4>
        <router-link
          :to="'/storesTransfers/create?store_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
          v-if="$checkRole('sales_payments_add')"
        >
          <i class="fas fa-plus"></i> {{ $t('Addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('Search for stores transfers operations')"
        :emptyTableText="$t('There are no stores transfers operations')"
        :filter="{ store_id: $route.params.id }"
        :withoutCard="true"
        :emptyTableSubText="$t('Try adding some items to the table and try again')"
        :cloumns="[
          {
            column: 'code',
            title: $t('Operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'from_store_id',
            title: $t('From store name'),
            element: 'fromStore',
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'to_store_id',
            title: $t('To store name'),
            element: 'toStore',
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: $t('The date of the operation'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: $t('description'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: $t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $checkRole('stores_transfers_edit')
              },
              { name: 'printStoresTransfer', role: true },
              {
                name: 'delete',
                role: $checkRole('stores_transfers_delete')
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $t('Attention'),
          areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
          close: $t('Close'),
          confirm: $t('Confirm'),
          loading: $t('Loading')
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/storesTransfers'
    }
  },
  components: {
    indexTable
  }
}
</script>
