var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Assets'),"description":_vm.$t('From here you can control the fixed assets')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for origin'),"emptyTableText":_vm.$t('There are no assets'),"buttonRole":_vm.$checkRole('capitals_add'),"localData":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('Customer name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'cost',
            title: _vm.$t('Purchase price'),
            type: 'text',
            sort: true,
            icon: 'money-bill-wave'
          },
          {
            column: 'date',
            title: _vm.$t('Purchase date'),
            type: 'text',
            sort: true,
            icon: 'calendar-alt'
          },
          {
            column: 'total',
            title: _vm.$t('Current Value'),
            type: 'text',
            sort: true,
            icon: 'money-bill-wave'
          },
          {
            column: 'stat',
            title: _vm.$t('Active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('Actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('capitals_edit')
              },
              {
                name: 'delete',
                role: _vm.$checkRole('capitals_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }