var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Sales payments'),"description":_vm.$t('From here you can control the sales payments')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('Find a batch'),"emptyTableText":_vm.$t('There are no sales payments'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('sales_payments_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('Situation'), value: '' },
          { title: _vm.$t('All'), value: '' },
          { title: _vm.$t('Draft'), value: 0 },
          { title: _vm.$t('Approved'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('Batch'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('Customer name'),
            type: 'clintLink',
            sort: true
          },
          {
            column: 'safe_id',
            title: _vm.$t('Treasury'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('Payment date'),
            type: 'text',
            sort: true
          },
          {
            column: 'invoice_id',
            title: _vm.$t('Invoice'),
            type: 'linkInvoice',
            to: 'invoice',
            sort: true,
            link: true
          },
          {
            column: 'description',
            title: _vm.$t('Thats about'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: _vm.$t('Amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: _vm.$t('Responsible name'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('Situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('Draft'), value: 0, color: 'dark' },
              { title: _vm.$t('Approved'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('sales_payments_edit')
              },
              { name: 'file' },
              { name: 'printSalesPayment', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('sales_payments_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }