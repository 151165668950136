<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Add bank Accounts')"
          :description="$t('From here you can create  bank accounts')"
          v-if="$route.name == 'bankAccountsCreate'"
        />
        <addHeader
          :title="$t('Edit bank accounts')"
          :description="$t('From here you can modify the bank accounts information')"
          v-if="$route.name == 'bankAccountsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('Bank account Basic information')"
            noLine="true"
          />
          <formInput
            :title="$t('Bank name')"
            :checkError="'nameErorr'"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('This field is required')"
            maxlength="255"
          />
          <monyInput
            :title="$t('Initial balance')"
            v-model="item.balance"
            :disabled="this.$route.name == 'bankAccountsEdit' ? true : false"
          />
          <formInput
            :title="$t('Conversion factor')"
            type="number"
            :checkError="'nameErorr'"
            v-model="item.exchange_rate"
            :hasErorr="errors.exchange_rate"
            :error="$t('This field is required')"
            maxlength="255"
          />
          <formInput
            :title="$t('Account name')"
            :checkError="'nameErorr'"
            v-model="item.bank_name"
            maxlength="255"
          />
          <formInput
            :title="$t('Account number')"
            :checkError="'nameErorr'"
            v-model="item.account_number"
            maxlength="255"
          />
          <checkInput :title="$t('Account case')" :value="item.stat" v-model="item.stat" />

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'bankAccountsCreate' && !$parent.stopEdit"
          >
            {{ $t('Add bank accounts') }}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'bankAccountsEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit bank accounts') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
export default {
  data() {
    return {
      path: '/safes',
      item: {
        stat: 1,
        bank_account: 1
      },
      errors: {
        name: false
      },
      readyUsers: 0
    }
  },
  methods: {
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (error == 0) {
        if (this.$route.name == 'bankAccountsCreate') {
          this.$parent.aletText = this.$t('safe successfully Added')
          this.$parent.alertType = 'success'
          this.$addToLocalDatabase(this.item, 'safes')
          this.$router.go(-1)
        } else if (this.$route.name == 'bankAccountsEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('customer Has Been Modified Successfully')
              this.$parent.alertType = 'success'
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$parent.aletText = $t('please Make Sure Of The Input')
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    getItem() {
      var item = this.$database.safes.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      this.$parent.stopEdit = false
    }
  },
  mounted() {
    if (this.$route.name == 'bankAccountsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }

    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    checkInput,
    loading
  }
}
</script>
