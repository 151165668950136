var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('Store operations'))+" ")])]),_c('indexTable',{attrs:{"emptyTableText":_vm.$t('There are no products'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"filter":{ product_id: _vm.$route.params.id },"withoutCard":true,"cloumns":[
        {
          column: 'store_id',
          title: _vm.$t('Store name'),
          type: 'link',
          to: 'store',
          sort: true,
          link: true
        },
        {
          column: 'quantity',
          title: _vm.$t('Quantity'),
          type: 'text'
        },
        {
          column: 'opreation',
          title: _vm.$t('The operation'),
          type: 'opreation'
        },
        { column: 'date', title: _vm.$t('Date'), type: 'text' },
        {
          column: 'type',
          title: _vm.$t('Operation type'),
          type: 'storeStat'
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }