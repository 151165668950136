<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('User Roles')"
          :description="$t('From Here You Can Control The Users Permissions')"
        />

        <indexTable
          :searchText="$t('Search For A Group')"
          :emptyTableText="$t('there Are No Roles')"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :buttonRole="$checkRole('roles_add')"
          :localData="true"
          :cloumns="[
            {
              column: 'name',
              title: $t('name'),
              type: 'text',
              sort: true
            },
            {
              column: 'users_count',
              title: $t('Users count'),
              type: 'text'
            },
            {
              column: 'options',
              title: $t('Settings'),
              type: 'options',
              options: [
                { name: 'edit', role: $checkRole('roles_edit') },
                {
                  name: 'delete',
                  role: $checkRole('roles_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/roles'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
