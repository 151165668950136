<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('Accounting')" :description="$t('Profits by invoices')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Report options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <div class="card">
            <div class="card-header col-12 p-4">
              <h4 class="card-header-title col-4">
                <i class="fal fa-chart-bar"></i>
                {{ $t('Profits by invoices') }}
              </h4>
              <div style="text-align: center" class="col-4"></div>
              <p class="col-4 text-left">
                {{ $t('From the date') }}
                {{ searchStartDate ? searchStartDate : '--' }}
                {{ $t('Into') }}
                {{ searchEndDate ? searchEndDate : '--' }}
              </p>
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-nowrap card-table table-hover">
                <tbody class="list" v-if="loading != 1">
                  <tr class="clum-head">
                    <td colspan="100" class="text-center">
                      <strong>{{ $t('Revenue') }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Total sales') }} </strong>
                    </td>
                    <td>{{ $moneyFormat(item.sales) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Sales profits') }} </strong>
                    </td>
                    <td>{{ $moneyFormat(item.salesEarning) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Total revenue') }}</strong>
                    </td>
                    <td>{{ $moneyFormat(item.totalEarning) }}</td>
                  </tr>
                  <tr class="clum-head">
                    <td colspan="100" class="text-center">
                      <strong>{{ $t('Expenses') }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Expenses') }}</strong>
                    </td>
                    <td>{{ $moneyFormat(item.expenses) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Total return sales') }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(item.returns) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Loss of return sales') }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(item.returnsLosing) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('Total expenses') }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(item.totalLosing) }}
                    </td>
                  </tr>
                  <tr class="clum-head">
                    <td>{{ $t('Profit') }}</td>
                    <td>
                      {{ $moneyFormat(item.netEarning) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import indexHeader from '@/elements/index/indexHeader.vue'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
export default {
  data() {
    return {
      path: '/accounting/profitsByInvoices',
      item: {},
      loading: 0,

      params: {
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      users: [],

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.loading = 1
      this.item = {}

      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.item = response.data
        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate
        this.loading = 0
      })
    },
    print() {
      var text = document.getElementById('reportShow').innerHTML
      var myframe = document.getElementById('printIframe')
      myframe.contentDocument.body.innerHTML =
        '<div class="text-center"> <img src="' +
        this.$linkGenerator(
          '/companiesUploads/' + localStorage.getItem('subdomain') + '/logos/' + this.$option.image
        ) +
        '" class="img-fluid mb-4" style="max-width: 4.5rem;" v-if="$option.image != null"><h2 class="mb-2">' +
        this.$option.name +
        '</h2></div>' +
        text
      setTimeout(function () {
        myframe.focus()
        myframe.contentWindow.print()
      }, 100)
    }
  },
  mounted() {
    this.$updateDatabase(['users'])
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
  },
  components: {
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
