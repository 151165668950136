var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('Transfers lockers'))+" ")]),(_vm.$parent.item.stat == 1 && _vm.$checkRole('safes_transfers_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/safesTransfers/create?to_safe_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('Addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('Search for transfer'),"emptyTableText":_vm.$t('There are no withdrawals operations'),"filter":{ to_safe_id: _vm.$route.params.id },"withoutCard":true,"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('Operation'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'from_safe_id',
          title: _vm.$t('From safe transfers'),
          element: 'fromSafe',
          type: 'link',
          to: 'safe',
          sort: true,
          link: true
        },
        {
          column: 'to_safe_id',
          title: _vm.$t('To safe transfers'),
          element: 'toSafe',
          type: 'link',
          to: 'safe',
          sort: true,
          link: true
        },
        {
          column: 'date',
          title: _vm.$t('Transfer date'),
          type: 'text',
          sort: true
        },
        {
          column: 'description',
          title: _vm.$t('Thats about'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$t('Amount'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$t('Settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('safes_transfers_edit')
            },
            {
              name: 'delete',
              role: _vm.$checkRole('safes_transfers_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
        close: _vm.$t('Close'),
        confirm: _vm.$t('Confirm'),
        loading: _vm.$t('Loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }