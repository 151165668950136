<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Bank accounts')"
          :description="$t('Bank accounts description')"
          :explanationPath="path + '/explaination'"
        />
        <indexTable
          :searchText="$t('Search for accounts')"
          :emptyTableText="$t('There are no accounts')"
          :buttonRole="$checkRole('bank_accounts_add')"
          :localData="true"
          :filter="{ bank_account: 1 }"
          :table="'safes'"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :cloumns="[
            {
              column: 'name',
              title: $t('Bank name'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'account_number',
              title: $t('Account number'),
              type: 'text',
              sort: true,
              icon: 'credit-card-front'
            },
            {
              column: 'bank_name',
              title: $t('Account name'),
              type: 'text',
              sort: true,
              icon: ' fa-user'
            },
            {
              column: 'balance',
              title: $t('Balance'),
              type: 'text',
              sort: true,
              icon: 'usd-square'
            },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'stat',
              sort: true,
              hideMobile: true
            },
            {
              column: 'options',
              title: $t('Actions'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $checkRole('bank_accounts_edit')
                },
                {
                  name: 'link',
                  role: $checkRole('safes_transfers_add'),
                  title: $t('Transfer From The Treasury'),
                  icon: 'suitcase',
                  link: '/safesTransfers/create?from_safe_id='
                },
                {
                  name: 'link',
                  role: $checkRole('safes_deposits_add'),
                  title: $t('Deposit to the safe'),
                  icon: 'donate',
                  link: '/safesDeposits/create?safe_id='
                },
                {
                  name: 'link',
                  role: $checkRole('safes_withdrawals_add'),

                  title: $t('Withdrawal From The Treasury'),
                  icon: 'money-bill-alt',
                  link: '/safesWithdrawals/create?safe_id='
                },
                {
                  name: 'delete',
                  role: $checkRole('bank_accounts_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/bankAccounts'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
