var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('Purchase Returns'),"description":_vm.$t('from Here You Can Control Purchase Returns')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('search For A Bill'),"emptyTableText":_vm.$t('there Are No Bills'),"emptyTableSubText":_vm.$t('Try adding some items to the table and try again'),"buttonRole":_vm.$checkRole('purchase_returns_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('Situation'), value: '' },
          { title: _vm.$t('All'), value: '' },
          { title: _vm.$t('Open'), value: 0 },
          { title: _vm.$t('Driven'), value: 1 },
          { title: _vm.$t('Canceled'), value: 2 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('Bill'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('The date of the invoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('Supplier name'),
            type: 'clintLink',
            sort: true
          },
          {
            column: 'total',
            title: _vm.$t('Cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'rest',
            title: _vm.$t('Balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: _vm.$t('Adminstrator'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('Situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('Open'), value: 0, color: 'dark' },
              {
                title: _vm.$t('Driven'),
                value: 1,
                color: 'success'
              },
              {
                title: _vm.$t('Canceled'),
                value: 2,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('purchase_returns_edit') && !_vm.$option.e_invoicing
              },
              { name: 'file' },
              { name: 'outLink', role: true },
              { name: 'printPurchaseReturn', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('purchase_returns_delete') && !_vm.$option.e_invoicing
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('Are you really sure to delete the item'),
          close: _vm.$t('Close'),
          confirm: _vm.$t('Confirm'),
          loading: _vm.$t('Loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }