<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('Assets')"
          :description="$t('From here you can control the fixed assets')"
        />
        <indexTable
          :searchText="$t('Search for origin')"
          :emptyTableText="$t('There are no assets')"
          :buttonRole="$checkRole('capitals_add')"
          :localData="true"
          :emptyTableSubText="$t('Try adding some items to the table and try again')"
          :cloumns="[
            {
              column: 'name',
              title: $t('Customer name'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'cost',
              title: $t('Purchase price'),
              type: 'text',
              sort: true,
              icon: 'money-bill-wave'
            },
            {
              column: 'date',
              title: $t('Purchase date'),
              type: 'text',
              sort: true,
              icon: 'calendar-alt'
            },
            {
              column: 'total',
              title: $t('Current Value'),
              type: 'text',
              sort: true,
              icon: 'money-bill-wave'
            },
            {
              column: 'stat',
              title: $t('Active'),
              type: 'stat',
              sort: true,
              hideMobile: true
            },
            {
              column: 'options',
              title: $t('Actions'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $checkRole('capitals_edit')
                },
                {
                  name: 'delete',
                  role: $checkRole('capitals_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('Attention'),
            areYouReallySureToDeleteTheItem: $t('Are you really sure to delete the item'),
            close: $t('Close'),
            confirm: $t('Confirm'),
            loading: $t('Loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/capitals'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
